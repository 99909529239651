import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';

import { DataService } from '../../services';

@Component({
	selector: 'app-date-range',
	templateUrl: './date-range.component.html',
	styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
	@Output() onDateSelect = new EventEmitter<Date>();
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	fromMax;
	toMax;
	toMin;
	private range: any = { fromDate: undefined, toDate: undefined };
	private subscriptions: Subscription = new Subscription();

	constructor(private ds: DataService) {
		this.setInitialLimits()
		const temp = this.ds.event.subscribe((data: any) => {
			if (data && data === 'clear') {
				this.range = { fromDate: undefined, toDate: undefined };
				this.fromDate = undefined;
				this.toDate = undefined;
				this.setInitialLimits();
				this.toMin = {}
			}
		});
		this.subscriptions.add(temp);
	}

	ngOnInit(): void { }

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	setInitialLimits(): void {
		const current = new Date();
		this.fromMax = {
			year: current.getFullYear(),
			month: current.getMonth() + 1,
			day: current.getDate()
		};
		this.toMax = {
			year: current.getFullYear(),
			month: current.getMonth() + 1,
			day: current.getDate()
		}
	}

	// on date range change
	onDateChange(type: string) {
		if (type === 'from') {
			this.range.fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
			this.toMin = {
				year: this.fromDate.year,
				month: this.fromDate.month,
				day: this.fromDate.day
			}
		} else {
			this.range.toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
			this.fromMax = {
				year: this.toDate.year,
				month: this.toDate.month,
				day: this.toDate.day
			}
		}
		console.log(this.range)
		if (this.range.fromDate || this.range.toDate) {
			this.onDateSelect.emit(this.range);
		}
	}
}