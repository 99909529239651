import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class ClearService {

  public clear:Subject<any> = new Subject();

  constructor() {}
}
