import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
  // Variable Declaration
  @Input() ratingText: boolean;
  @Input() rating: number;
  @Input() maxRating?: number;
  @Input() totalRating : number; 
  selected = 0;
  hovered = 0;
  readonly = false;
  

  // Form integration
  ctrl = new FormControl(null, Validators.required);

  toggle() {
    if (this.ctrl.disabled) {
      this.ctrl.enable();
    } else {
      this.ctrl.disable();
    }
  }
}
