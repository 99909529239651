import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES, ApiRoutes } from '../../../shared/routes';

import { DataService, ToastService } from '../../services';
import { IBusinessOwners } from 'app/interfaces';
import { environment } from 'environments/environment';
import { AuthService } from 'app/shared/auth';
import { ClearService } from 'app/pages/full-pages/dashboard-management/dashboard/clear.service';
import * as moment from 'moment';
import { keys } from 'core-js/core/array';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
// import { serviceCategory } from '../../../pages/full-pages/service-provider/service.config';

@Component({
	selector: 'app-table-header',
	templateUrl: './table-header.component.html',
	styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
	@ViewChild('search', { static: false }) search: any;
	@ViewChild('limit', { static: false }) limit: any;
	public entries: any[] = [10, 25, 50, 100, 200];
	@Output() valueChange = new EventEmitter();
	@Input() config: any;
	@Input() totalcount : any;
	@Input() columns: any;
	private subscriptions: Subscription = new Subscription();
	title: string = '';
	serviceCategory: any[];
	selectedServiceCategory: string = '';
    currentUrl ;
	filterObj: any;
	resp = '';
	searchQuestion:string;
	constructor(private route: ActivatedRoute,private cs : ClearService,private router :Router, private ds: DataService,private toastr: ToastService,public auth: AuthService) { }

	offerHeader = ['Nr.','Gutschein-Bezeichnung', 'Bereich','Gutschein-Code', 'Anzahl Bewertungen für Gutschein-Erhalt', 'Tage zwischen Einlösungen','Beschreibung','Angeboten an','Eingelöst am'];
	ngOnInit(): void {
		registerLocaleData(localeDe, 'de');

		if(localStorage.getItem('locale')=='en'){
			this.offerHeader=[];
			this.offerHeader=['S.N','Offer Name', 'Category','Coupon Code', 'Min Rating', 'Redeem Days Gap','Description','Offered To','Redeemed At']
		}
		const temp = this.route.data.subscribe(data => this.title = data.title);
		this.subscriptions.add(temp);
        this.currentUrl = this.router.url;
		
	}

	public ngAfterViewInit(): void {
		// Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		// Add 'implements AfterViewInit' to the class.
		if (this.search) {
			fromEvent(this.search.nativeElement, 'keydown').pipe(debounceTime(500), map(x => x['target']['value'])).subscribe(value => {

				this.cs.clear.subscribe(resp=>{
                    if(resp && value != ''){
						// setTimeout(() => {
							this.search.nativeElement.value = ''
							value = ''
						// }, 1000);
					}else{

					}
				})

				this.updateFilter(value);
			
			});
		}
	}

	// date range event handler
	onDateSelect(eventType: string ) {
		const obj = {
			startDate: this.startDateValue,
			endDate: this.endDateValue,
			eventType: eventType
		}
		this.valueChange.emit(obj);
	}
	// ------------------------

	// on service category dd change
	onSpCategoryChange(e, eventType: string = 'spcategory'): void {
		this.filterObj = {
			val: e.target.value,
			eventType: eventType
		}
		// this.valueChange.emit(obj);
	}

	// text search
	private updateFilter(val: string, eventType: string = 'textSearch') {
		const value = val.toString().toLowerCase().trim();
		const obj = {
			val: value,
			startDate: this.startDateValue,
			endDate: this.endDateValue,
			eventType: eventType
		}
		this.searchQuestion=val;
		this.valueChange.emit(obj);
	}

	//  limit change
	public onLimitChange(e, eventType: string = 'pageLimitChange'): void {
		const obj = {
			val: parseInt(e.target.value),
			eventType: eventType
		}
		this.valueChange.emit(obj);
	}

	//  filter by change
	public onFilterByChange(e, eventType: string = 'filterByChange'): void {
		const obj = {
			val: e.target.value,
			eventType: eventType
		}
		this.valueChange.emit(obj);
	}

	// add item
	public add(eventType: string = 'add'): void {
		const obj = {
			val: -1,
			eventType: eventType
		}
		this.valueChange.emit(obj);
	}

	// apply filter action
	onApply(eventType: string = 'apply'): void {
		this.valueChange.emit(this.filterObj);
	}

	// clear all filters
	onClear(eventType: string = 'clear'): void {
		this.selectedServiceCategory = '';
		this.search.nativeElement.value = '';
		this.ds.event.emit(eventType);
		const obj = {
			val: null,
			eventType: eventType
		}
		this.valueChange.emit(obj);
	}

	// Date picker
	 startDateValue:any; 
	 endDateValue :any;

	datePicker(startDateInput: HTMLInputElement, endDateInput: HTMLInputElement) {
		this.startDateValue = startDateInput.value;
		this.endDateValue = endDateInput.value;
		this.onDateSelect('daterange');
	  }

	public pagePayload: any = {
		page_no: 1,
		page_length: 10,
		offerType: 'internal',
		search: ''
	}
	selectedBusinessOwners: IBusinessOwners[] = [];

   

	getCSV() {
		if(this.pagePayload.role == 'admin') {
		if (Array.isArray(this.selectedBusinessOwners) && !this.selectedBusinessOwners.length) {
			//console.log('array emtpy');
			this.toastr.error('Please select business owner.');
			return;
		}}
		const url: string = ApiRoutes.getCSV;
		const payload = {
			// bussinessOwnerIds: this.selectedBusinessOwners
			bussinessOwnerIds : this.pagePayload.role == 'admin' ? this.selectedBusinessOwners :[this.auth.addedBy]
		}
		// console.log(payload)
		this.ds.post(url, payload).subscribe((resp) => {
			if (resp.success) {
				// //this.serviceCategories = res.data
				// var hiddenElement = document.createElement('a');
				// hiddenElement.href = environment.apiBaseUrl + '/' + res.csv;
				// //hiddenElement.target = '_blank';
				// //hiddenElement.download = 'people.csv';
				// hiddenElement.click()
				this.logo = resp.logo;
				for (let i of resp.data) {
				  this.index ++;
				  this.newArray = this.createRange(8)
				  this.newArray[0] = this.index
				  this.newArray[1] = i?.offerName
				 
				  this.newArray[2]=  i?.category
				  
				  this.newArray[3] = i?.couponCode
				  this.newArray[4] = i?.minRating
				  this.newArray[5] = i?.redeemDaysGap
				 
				  this.newArray[6]=  i?.description;
				  
				  this.newArray[7] = i?.offeredTo;
				  this.newArray[8] = i?.redeemedAt;
				 
				  this.data.push(this.newArray)
				  this.newArray = []
				}
				this.index = 0;
			   // (i?.rating) ? i.rating?.ratingCount : '',
		  
				this.generateExcelForOfferHistory();
		  
			} else {
				this.toastr.error('Data not found');
			}
		}, error => {
		});
		//}
	}

	async generateExcelForOfferHistory() {
        const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = currentDate.getFullYear();
            const germanDate = `${day}.${month}.${year}`;
		setTimeout(() => {
		  const workbook = new Workbook();
		  const worksheet = workbook.addWorksheet('Redemption-history_'+germanDate);
	   
	
		  //worksheet.mergeCells('A1:A3');
		  var imageId2 = workbook.addImage({
			base64: this.logo,
			extension: 'png',
		  })
		  worksheet.getRow(1).height = 60;
		  worksheet.addImage(imageId2, 'A1:A1');
		  worksheet.getCell('B1').value = this.qes
		  worksheet.getCell('B1').font = {
			name: 'Arial',
			family: 4,
			size: 12,
			bold: true
		  };
		  worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'left' };
		  
		  const headerRow = worksheet.addRow(this.offerHeader);
		  headerRow.height = 25;
		  headerRow.fill = {
			type: 'pattern',
			pattern:'solid',
			fgColor:{argb:'f1f1f1'},
		  }

		  headerRow.alignment = {
			vertical: 'middle'
		  }
		  headerRow.eachCell((cell, number) => {
			cell.font = {
			  name: 'Arial',
			  family: 4,
			  size: 10,
			  bold: true
			};
			headerRow.alignment = {
			  indent: 1,
			  vertical: 'middle',
			
			}
		
		  });
		  // Add Data and Conditional Formatting
			  this.data.forEach(d => {
				const row = worksheet.addRow(d);
			  row.height = 40
				row.worksheet.properties.defaultRowHeight = 50
				row.alignment = {
				  vertical: 'middle',
				  // horizontal: 'left',
				  indent: 1,
				  wrapText: true,
				  
				}
			  }
			  );
			  worksheet.getColumn(1).alignment =  {
				horizontal: 'center',
				vertical: 'middle'
			  }
		  worksheet.getColumn(1).width = 12;
		  worksheet.getColumn(2).width = 30;
		  worksheet.getColumn(3).width = 30;
		  worksheet.getColumn(4).width = 30;
		  worksheet.getColumn(5).width = 35;
		  worksheet.getColumn(6).width = 40;
		  worksheet.getColumn(7).width = 40;
		  worksheet.getColumn(8).width = 40;
	
		  workbook.xlsx.writeBuffer().then((data: any) => {
			
			const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			let redeemhistory= 'Gutschein-Historie_'
			if(localStorage.getItem('locale')=='en'){
				redeemhistory='Redemption-history_'
			}
			fs.saveAs(blob,redeemhistory+germanDate + '.xlsx');
			window.location.reload();
		  });
		}, 600);
	
	  }
	

	header = ['Nr.','Bewertung','Bewertungslabels','Weiterer Kommentar' , 'Antworten','Bewerter, E-Mailadresse' , 'Service Person/Team Name, E-Mailadresse', 'Datum, Zeit'];
	dnheader = ['S.N','Rating','Rating Labels','Comment'  ,'Answer','Rater, E-Mail Address' , 'Service Person/Team Name, E-Mail Address', 'Date, Time'];
 	 data: any = [];
  	newArray: any;
	logo:any;
	index:number = 0;
	qes:any;
	myDate = moment().format('DD-MM-YYYY');

	createRange(num) {
		return new Array(num);
	  }
	
	downloadButton:boolean=false  
	downloadAllQues(){
		this.downloadButton=true
		const url= ApiRoutes.multiQuestionExl;
		let payload={
			search: this.searchQuestion,
			addedBy: this.auth.userId,
			startDate: this.startDateValue,
			endDate: this.endDateValue,
		}
		this.ds.post(url,payload).subscribe(async (resp:any)=>{
			
			this.logo=resp?.logo
			
			let newdata=[]
			for (const key in resp.data) {
				if (resp.data.hasOwnProperty(key)) {	
				  const objects = resp.data[key];
				  newdata.push({question:key,allquestions:objects})
				}
			  }
			newdata.forEach(que=>{
				this.qes=que.question;
				for (let i of que.allquestions) {
					this.index ++;
					this.newArray = this.createRange(5)
					this.newArray[0] = this.index
					this.newArray[1]=i?.rating
					this.newArray[2]= i?.userRatingLabel
					this.newArray[3]= i?.comment
					this.newArray[4] = i?.answer
					this.newArray[5]=  (i?.fullName? i.fullName:'') + ', '+' '+ (i?.guest_email ? i.guest_email : '')
				  
					this.newArray[6] = i?.type == 'team' && i?.teamID ? (i?.teamName) : ((i?.firstName ? i.firstName : '') + ' ' + (i?.lastName ? i.lastName : '') + ', ' + (i?.email ? i.email : ''));
			
					this.newArray[7] = ([moment(i?.createdAt).local().format('DD.MM.YYYY, hh:mm')]) +' '+'Uhr';
					this.newArray[8] = i?.question
					this.newArray[9] = i?.category
					this.data.push(this.newArray);
					
					this.newArray = []
				  }
				
				  this.index = 0;
			
				//  
			})
			const result = this.data.reduce((acc, curr) => {
				const questionKey = curr[8]; // Use the 6th element as the key for grouping the questions
				const categoryKey = curr[9]; // Use the 7th element as the key for grouping the categories
				const existingObj = acc.find(obj => obj.question === questionKey);
				if (existingObj) {
				  existingObj.answer.push(curr.slice(0, 8).concat(curr.slice(10))); // Remove the 6th element from the answer array
				} else {
				  const newObj = { question: questionKey, answer: [curr.slice(0, 8).concat(curr.slice(10))], category: categoryKey }; // Remove the 6th element from the answer array
				  acc.push(newObj);
				}
				
				return acc;
			  }, []);
			  
			this.data=[];
			this.data=result
		  let res= await this.generateExcelForSingle(result);
			// window.location.href= APP_ROUTES.ratingQuestionManagement
		},(err)=>{
				this.downloadButton=false;		
		})
		
	}


    async generateExcelForSingle(result) {
        setTimeout(() => {
          const workbook = new Workbook();
		  let num=1;
          result.forEach(e => {
            const worksheet = workbook.addWorksheet(e.category +'-'+num);
			num++;
            var imageId2 = workbook.addImage({
              base64: this.logo,
              extension: 'png',
            });
            worksheet.getRow(1).height = 60;
            worksheet.addImage(imageId2, 'A1:A1');
            // worksheet.getCell('B1').value = e.question;
			worksheet.getCell('B1').value = e.category;
            worksheet.getCell('B1').font = {
              name: 'Arial',
              family: 4,
              size: 12,
              bold: true
            };
            worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'left' };

			// worksheet.getCell('C1').value = e.category;
			worksheet.getCell('C1').value = e.question;
			worksheet.getCell('C1').font = {
				name: 'Arial',
				family: 4,
				size: 12,
				bold: true
			  };
			  worksheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'left' };
			let headers = this.header
			  if(localStorage.getItem('locale')=='en'){
				headers= this.dnheader
			  }   
			const headerRow = worksheet.addRow(headers);  
            headerRow.height = 25;
            headerRow.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'f1f1f1' },
            };
            headerRow.alignment = {
              vertical: 'middle'
            };
            headerRow.eachCell((cell, number) => {
              cell.font = {
                name: 'Arial',
                family: 4,
                size: 10,
                bold: true
              };
              headerRow.alignment = {
                indent: 1,
                vertical: 'middle',
              };
            });

			
            e.answer.forEach(d => {
              const row = worksheet.addRow(d);
              row.height = 40;
              row.worksheet.properties.defaultRowHeight = 50;
              row.alignment = {
                vertical: 'middle',
                indent: 1,
                wrapText: true,
              };
            });
            worksheet.getColumn(1).alignment = {
              horizontal: 'center',
              vertical: 'middle'
            };
            worksheet.getColumn(1).width = 12;
            worksheet.getColumn(2).width = 40;
            worksheet.getColumn(3).width = 40;
            worksheet.getColumn(4).width = 40;
            worksheet.getColumn(5).width = 30;
            worksheet.getColumn(6).width = 30;
			worksheet.getColumn(7).width = 30;
			worksheet.getColumn(8).width = 30;
          });
          workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			let sheetName = 'Fragen_alle_Antworten_';
			
			if(localStorage.getItem('locale')=='en'){
				sheetName = 'Questions_all_Answers_'
			}
            fs.saveAs(blob, sheetName + this.myDate + '.xlsx');
			window.location.reload();
			// this.router.navigate([APP_ROUTES.slash + APP_ROUTES.ratingQuestionManagement], { skipLocationChange: true });
          });
        }, 600);
		
      }
  }

