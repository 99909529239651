import { RouteInfo } from './vertical-menu.metadata';
import { APP_ROUTES } from '../routes';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
	{
		path: APP_ROUTES.slash + APP_ROUTES.dashboard, title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'businessOwner']
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.businessLevels, title: 'Business Level Management', icon: 'ft-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'businessOwner']
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.industries, title: 'Industry Management', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.businessOwnerManagement, title: 'Business Owner Management', icon: 'ft-edit', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'businessOwner']
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.resetPassword, title: 'Change Password', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']
	},
	/* {
		path: APP_ROUTES.slash + APP_ROUTES.gaDashboard, title: 'GA Dashboard', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.user, title: 'User management', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.serviceProviders, title: 'Service provider', icon: 'ft-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.category, title: 'Toolkit', icon: 'ft-edit', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.questionnaire, title: 'Questionnaire', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
	},
	{
		path: '', title: 'Informative Pages', icon: 'ft-align-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
		submenu: [
			{ path: '/' + APP_ROUTES.about, title: 'About Us', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.contact, title: 'Contact Us', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.terms, title: 'Terms', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.privacy, title: 'Privacy', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			{ path: '/' + APP_ROUTES.disclaimer, title: 'Disclaimer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			// {
			// 	path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
			// 	submenu: [
			// 		{ path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			// 		{ path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
			// 	]
			// },
		]
	}, */
];
