import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class LocalStorage {

    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    public clear(): void {
        // if (isPlatformBrowser(this.platformId)) { }
        localStorage.clear();
    }

    public getItemString(key: string): string {
        return localStorage.getItem(key);
    }

    public getItem(key: string) {
        if (key === 'token' || key == `likedyou-currentUser-businessName`) {
            return localStorage.getItem(key);
        }
        return JSON.parse(localStorage.getItem(key));
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    public setItem(key: string, data: string): void {
        localStorage.setItem(key, data);
    }
}
