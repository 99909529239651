export class APP_ROUTES {
    public static root: string = '';
    public static slash: string = '/';
    public static previous: string = '../';
    public static new: string = 'new';
    
    //* Content layout */
    public static error: string = 'error';
    public static verification: string = 'verification';
    public static login: string = 'login';
    public static businessOwnerLogin: string = 'business-owner-login';
    public static businessOwnerSetPassword: string = 'set-password';
    public static createServiceProviderPassword: string = 'service-provider-password';
    public static register: string = 'register';
    public static forgotPassword: string = 'forgot-password';
    public static resetPassword: string = 'reset-password';
    public static Boapproval : string = 'Bo-approval'
    public static verifyAccount: string = 'verify-account';
    public static installOpenApp : string ='install-open-app';
    public static messagePage : string = 'message';
 
    //* Full Layoyt */
    public static dashboard: string = 'dashboard';
    public static manageProfile: string = 'manage-profile';
    public static manageInfoPages : string = 'informative-pages-management'
    public static businessLevelsManagement: string = 'business-levels-management';
    public static businessLevels: string = 'business-levels';
    public static businessLevel: string = 'business-level';
    public static industriesManagement: string = 'industries-management';
    public static industries: string = 'industries';
    public static industry: string = 'industry';
    public static businessOwner: string = 'business-owner';
    public static businessOwnerManagement: string = 'business-owner-management';
    public static requestedBusinessOwners: string = 'requested-business-owners';
    public static existingBusinessOwners: string = 'existing-business-owners';
    public static rejectedBusinessOwners: string = 'rejected-business-owners';
	public static serviceProviderManagement: string = 'create-service-provider';
	public static serviceProvider: string = 'service-provider';
	public static spDetail: string = 'service-provider-detail';
	public static serviceCategoryManagement: string = 'service-category-management';
	public static ratingQuestionManagement: string = 'rating-question-management';
	public static existingServiceProviders: string = 'existing-service-providers';
    public static createTeam: string = 'create-team';
    public static existingTeams: string = 'existing-teams';
    public static teams: string = 'team';
    public static teamRating:string = 'team-ratings'
    public static teamDetail: string = 'team-detail';
    public static serviceProviders: string = 'service-provider';
    public static qrCodeManagement: string = 'qr-code-management';
    public static designationManagement: string = 'designation-management';
    public static offer: string = 'offer';
	public static customOfferManagement: string = 'custom-offer';
	public static redeeemHistory: string = 'redeem-history';
    public static offers: string = 'update-offer';
    public static aboutUs: string = 'about-us';
    public static terms: string = 'terms';
    public static contactUs: string = 'contact-us';
    public static faqManagement: string = 'faq-management';
    public static faq: string = 'faq';
    public static statistics:string = 'statistics';
    public static teamManagement = 'team-management'
    public static dashboardManagenet = 'dashboard-management'
    public static offerManagement = 'offer-management'
    public static SPManagement = 'sp-management'
    public static BOManagement = 'BO-management'
    // public static disclaimer: string = 'disclaimer';
    



    //* Mobile Screen */
    static mobilescreenlogin: string = 'login';
    static mobilescreencomments: string = 'comments';
    static mobilescreenratings: string ='ratings';
    static mobilescreenTeamratings: string ='team-ratings';
    static mobilescreenquestions: string ='question';
    static mobilescreenfeedback: string = 'feedbackmodel';
    static mobilescreenemail: string = 'emailtemp';
    static mobilescreenaccountverification: string = 'account-verification';
    
    static mobilescreenerror: string = '**'
}