<p>{{'Onboarding.Manage_profile_title'| translate}}</p>
<div class="row">
    <div class="col-md-12">
        <div class="card m-0">
            <div class="card-content">
                <div class="card-body">
                    <h5 *ngIf="emailCheck">{{'update_business_owner' | translate}} </h5>
                    <h5 *ngIf="!emailCheck">{{'business_owners' | translate}} </h5>
                    <div class="tab-content">
                        <form [formGroup]="editBusinessOwnerForm">
                            <div class="row manage-profile-row">
                                <div class="col-xl-9 col-md-8  mpr-left">


                                    <div class="form-group row">
                                        <label for="businessName" class="col-lg-3 col-form-label">{{'business_name' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="businessName" class="form-control" placeholder="{{'business_name' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': isSubmitting && rf.businessName.invalid, 'is-valid': isSubmitting && !rf.businessName.invalid }"
                                                required>
                                            <div *ngIf="isSubmitting && rf.businessName.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.businessName.errors?.required">
                    {{'business_name_error' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="salutation" class="col-lg-3 col-form-label">{{'salutation' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <select [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="salutation" class="form-control" [ngClass]="{ 'is-invalid': isSubmitting && rf.salutation.invalid, 'is-valid': isSubmitting && !rf.salutation.invalid }"
                                                required>
                    <option value="">--- {{'select_salutation' | translate}} ---</option>
                    <option value="Mr.">{{'Mr' | translate}}</option>
                    <option value="Mrs.">{{'Mrs' | translate}}</option>
                    <!-- <option>{{'Miss' | translate}}</option> -->
                </select>
                                            <div *ngIf="isSubmitting && rf.salutation.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.salutation.errors?.required">{{'salutation_error' | translate}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="lastName" class="col-lg-3 col-form-label">{{'last_name' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="lastName" class="form-control" placeholder="{{'last_name' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': isSubmitting && rf.lastName.invalid, 'is-valid': isSubmitting && !rf.lastName.invalid }"
                                                required>
                                            <div *ngIf="isSubmitting && rf.lastName.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.lastName.errors?.required">
                    {{'last_name_error' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="firstName" class="col-lg-3 col-form-label">{{'first_name' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="firstName" class="form-control" placeholder="{{'first_name' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': isSubmitting && rf.firstName.invalid, 'is-valid': isSubmitting && !rf.firstName.invalid }"
                                                required>
                                            <div *ngIf="isSubmitting && rf.firstName.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.firstName.errors?.required">
                    {{'first_name_error' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="email" class="col-lg-3 col-form-label">{{'email_add' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="user && user.status === 'verified' ? 'disabled' : null" formControlName="email" class="form-control" placeholder="{{'email_address' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': isSubmitting && rf.email.invalid, 'is-valid': isSubmitting && !rf.email.invalid }"
                                                required>
                                            <div *ngIf="isSubmitting && rf.email.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.email.errors?.required">{{'email_address_error' | translate}}</small>
                                                <small [hidden]="!rf.email.errors?.pattern">{{'email_address_is_invalid' | translate}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                <label for="contactNumber" class="col-sm-3 col-form-label">Contact Number</label>
                <div class="controls col-sm-6">
                  <input type="text" [attr.disabled]="!profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="contactNumber" class="form-control" placeholder="Contact Number" autocomplete="off"
                  [ngClass]="{ 'is-invalid': isSubmitting && rf.contactNumber.invalid, 'is-valid': isSubmitting && !rf.contactNumber.invalid }"
                  required>
                  <div
                  *ngIf="isSubmitting && rf.contactNumber.invalid"
                  class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                  <small [hidden]="!rf.contactNumber.errors?.required">
                    *Contact number is required.
                  </small>
                  <small [hidden]="!rf.contactNumber.errors?.pattern">
                    *Contact number is invalid.
                  </small>
                  </div>
                </div>
              </div> -->
                                    <div class="form-group row">
                                        <label for="contactNumber" class="col-lg-3 col-form-label">{{'contact_numberr' | translate }}</label>
                                        <div class="controls col-lg-9">
                                            <!-- <input type="text" [attr.disabled]="!profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="contactNumber" class="form-control" placeholder="Contact Number" autocomplete="off"
                  [ngClass]="{ 'is-invalid': isSubmitting && rf.contactNumber.invalid, 'is-valid': isSubmitting && !rf.contactNumber.invalid }"
                  required> -->
                                            <input type="text" id="phonee" #phoneInput placeholder="0160 123 4567" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'de'}" (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                                                formControlName="contactNumber" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" class="form-control" [ngClass]="{ 'is-invalid': isSubmitting && rf.contactNumber.invalid, 'is-valid': isSubmitting && !rf.contactNumber.invalid }"
                                                autocomplete="off" required size="80" />
                                            <div *ngIf="isSubmitting && rf.contactNumber.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.contactNumber.errors?.required">
                    {{'contact_no_is_required' | translate}}
                  </small>
                                                <small [hidden]="!rf.contactNumber.errors?.pattern">
                    {{'contact_number_is_invalid' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="addressOfBusiness" class="col-lg-3 col-form-label">{{'address_line' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="addressOfBusiness" class="form-control" placeholder="{{'address_line 1' | translate}}" autocomplete="off"
                                                [ngClass]="{ 'is-invalid': isSubmitting && rf.addressOfBusiness.invalid, 'is-valid': isSubmitting && !rf.addressOfBusiness.invalid }" required>
                                            <div *ngIf="isSubmitting && rf.addressOfBusiness.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.addressOfBusiness.errors?.required">
                    {{'address_line_1_is_required' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="streetNumber" class="col-lg-3 col-form-label">{{'address_line optional' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="streetNumber" class="form-control" placeholder="{{'address_line 2 optional' | translate}}" autocomplete="off"
                                                [ngClass]="{ 'is-invalid': isSubmitting && rf.streetNumber.invalid, 'is-valid': isSubmitting && !rf.streetNumber.invalid }">
                                            <div *ngIf="isSubmitting && rf.streetNumber.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.streetNumber.errors?.pattern">
                    {{'address_line_2_is_invalid' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="city" class="col-lg-3 col-form-label">{{'city' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="city" class="form-control" placeholder="{{'city' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': isSubmitting && rf.city.invalid, 'is-valid': isSubmitting && !rf.city.invalid }"
                                                required>
                                            <div *ngIf="isSubmitting && rf.city.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.city.errors?.required">
                    {{'city_error' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="postalCode" class="col-lg-3 col-form-label">{{'postal_code' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="postalCode" class="form-control" placeholder="{{'postal_code' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': isSubmitting && rf.postalCode.invalid, 'is-valid': isSubmitting && !rf.postalCode.invalid }"
                                                required>
                                            <div *ngIf="isSubmitting && rf.postalCode.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.postalCode.errors?.required">
                    {{'postal_code_error' | translate}}
                  </small>
                                                <small [hidden]="!rf.postalCode.errors?.pattern">
                    {{'postal_code_is_invalid' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="country" class="col-lg-3 col-form-label">{{'country' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="country" class="form-control" placeholder="{{'country' | translate}}" autocomplete="off" [ngClass]="{ 'is-invalid': isSubmitting && rf.country.invalid, 'is-valid': isSubmitting && !rf.country.invalid }"
                                                required>
                                            <div *ngIf="isSubmitting && rf.country.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.country.errors?.required">
                    {{'country_error' | translate}}
                  </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="businessCategory" class="col-lg-3 col-form-label">{{'business_category_(star)' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <!-- <select [attr.disabled]="!profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="businessCategory" class="form-control"
                  [ngClass]="{ 'is-invalid': isSubmitting && rf.businessCategory.invalid, 'is-valid': isSubmitting && !rf.businessCategory.invalid }"
                  required>
                    <option value="">--- Select business category ---</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                  </select> -->
                                            <ngb-rating formControlName="businessCategory" [max]="6" [readonly]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null">
                                                <ng-template let-fill="fill" let-index="index">
                                                    <span class="star" [class.filled]="fill === 100">&#9733;</span>
                                                </ng-template>
                                            </ngb-rating>
                                            <div *ngIf="isSubmitting && rf.businessCategory.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.businessCategory.errors?.required">{{'business_category_is_required' | translate}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="industryType" class="col-lg-3 col-form-label">{{'industry_type' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <select [attr.disabled]="auth.role != 'admin' && !profile && user && user.status === 'verified' ? 'disabled' : null" formControlName="industryType" class="form-control" [ngClass]="{ 'is-invalid': isSubmitting && rf.industryType.invalid, 'is-valid': isSubmitting && !rf.industryType.invalid }"
                                                required>
                  <option value="">--- {{'select_industry_type' | translate}} ---</option>
                  <option *ngFor="let type of industryTypes" [value]="type.id">{{type.title}}</option>
                  </select>
                                            <div *ngIf="isSubmitting && rf.industryType.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.industryType.errors?.required">{{'industry_type_error' | translate}}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <!--test -->
                                    <div class="form-group row" *ngIf="!profile && (user?.status === 'unverified' || user?.status === 'rejected')">
                                        <label for="businessLevel" class="col-lg-3 col-form-label">{{'business_level' | translate}}</label>
                                        <div class="controls col-lg-9">
                                            <input type="text" formControlName="businessLevel" class="form-control" *ngFor="let level of businessLevels" [value]="level.id" [(ngModel)]="level.name" [ngClass]="{ 'is-invalid': isSubmitting && rf.businessLevel.invalid, 'is-valid': isSubmitting && !rf.businessLevel.invalid }"
                                                disabled>
                                            <!-- <select formControlName="businessLevel" class="form-control"
                  [ngClass]="{ 'is-invalid': isSubmitting && rf.businessLevel.invalid, 'is-valid': isSubmitting && !rf.businessLevel.invalid }"
                  required>
                  <option value="">--- {{'select_business_level' | translate}} ---</option>
                    <option *ngFor="let level of businessLevels" [value]="level.id">{{level.name}}</option>
                  </select> -->
                                            <div *ngIf="isSubmitting && rf.businessLevel.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                                                <small [hidden]="!rf.businessLevel.errors?.required">{{'business_level_error' | translate}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-sm-row flex-column justify-content-start">
                                        <button *ngIf="auth.role == 'admin' && user?.status == 'unverified'" class="btn btn-liked-you mr-sm-2 mb-1" (click)="action(user, 'approve', '')">{{'approve' | translate}}</button>
                                        <!-- <button *ngIf="auth.role == 'admin' && user?.status != 'unverified'" [disabled]="user?.status == 'verified'" class="btn btn-liked-you mr-sm-2 mb-1" (click)="action(user, 'approve', '')">{{ user?.status == 'verified' ? ('saving' | translate): ('saving' | translate) }}</button> -->
                                        <button *ngIf="emailCheck" type="submit" class="btn btn-liked-you mr-sm-2 mb-1" (click)="onSubmit()">{{'update1' | translate}}</button>
                                        <button *ngIf="auth.role == 'admin'" [disabled]="user?.status == 'rejected' || user?.status == 'verified'" class="btn btn-danger mr-sm-2 mb-1" (click)="action(user, 'disaprrove', '')">{{ user?.status == 'rejected' ? ('deleting' | translate) : ('deleting' | translate) }}</button>

                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-4 mpr-right">
                                    <div class="image-upload text-center">

                                        <div class="controls d-inline">
                                            <img *ngIf="isLogo && croppedImage==''" [src]="showimage" class="rounded-circle w-100"  style="height:190px; width:190px !important; object-fit: cover; object-position: center; " >
                                            <img *ngIf="!isLogo && croppedImage==''" [src]="imageUrl" class="rounded-circle w-100">
                                            <div width="500" height="500">
                                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="18 / 18" [cropperMinWidth]="80" [onlyScaleDown]="true" [roundCropper]="false" format="png" outputType="base64" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" [style.display]="showCropper ? null : 'none'" [alignImage]="'left'"></image-cropper>
                                            </div>
                                            <!-- <div style="color: red;" *ngIf="fileSizeError">
                                File pixel is should be greater than 512*512 and smaller than 750*750
                            </div> -->
    



                                            <div class="text-center d-flex justify-content-center" *ngIf="emailCheck">
                                                <div  class="text-center w-100 d-flex justify-content-center">

                                                    <input name="file" id="file" class="inputfile" type="file" accept=".png, .jpg, .jpeg " (change)="fileChangeEvent($event)" />

                                                    <label for="file" class="upload-btn mt-2">{{'upload_logo' | translate}}</label>
                                                    
                                                </div>
                                                <!-- <label *ngIf="isLogo && croppedImage" (click)="uploadFileOnServer()" class="upload-btn mt-2"> {{'update_logoo' | translate}}</label>


                                                <div *ngIf="!isLogo && croppedImage == ''">
                                                    <input type="file" name="file" id="file" class="inputfile" accept=".png, .jpg, .jpeg" (change)="fileChangeEvent($event)" />

                                                    <label for="file" class="upload-btn mt-2"> {{'add_image' | translate}}</label>
                                                </div>
                                                value="{{'add_image' | translate}}" -->


                                                <!-- <button *ngIf="!isLogo && croppedImage" class="btn btn-liked-you mr-sm-2 mb-1" (click)="uploadFileOnServer()">
                                                      {{'upload_logo' | translate}}
                                                </button> -->
                                                

                                                <!-- <button *ngIf="!isLogo" class="btn btn-liked-you mr-sm-2 mb-1" (click)="fileInput.click()">{{'add_image' | translate}}</button> -->
                                            </div>
                                            <div class="text-center d-flex justify-content-center" *ngIf="emailCheck">
                                            <p style="text-align:center; font-size: 10px;">{{'upload_bo_logo_text' | translate}}.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                        <!-- <div class="d-flex flex-sm-row flex-column justify-content-center">
                <button [disabled]="user?.status == 'verified'"  class="btn btn-liked-you btn-raised mb-0 mr-1 p-7 " (click)="action(user, 'approve', '')">{{ user?.status == 'verified' ? 'Approved' : 'Approve' }}</button>
              <button [disabled]="user?.status == 'rejected'"  class="btn btn-danger btn-raised mb-0 mr-1 p-7" (click)="action(user, 'disaprrove', '')">{{ user?.status == 'rejected' ? 'Rejected' : 'Reject' }}</button>
              </div> -->

                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<div id="levelFormModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
        <span class="close" (click)="closeModal()">&times;</span>
        <h3>Assign Level</h3>
        <form [formGroup]="assignLevelForm" (ngSubmit)="onModalSubmit()">
            <div class="form-group row mt-2">
                <label for="assignBusinessLevel" class="col-sm-4 col-form-label">{{'business_level' | translate}}</label>
                <div class="controls col-sm-8">
                    <select formControlName="assignBusinessLevel" class="form-control" [ngClass]="{ 'is-invalid': isSubmittingleveform && rflevel.assignBusinessLevel.invalid, 'is-valid': isSubmittingleveform && !rflevel.assignBusinessLevel.invalid }" required>
          <option value="">--- {{'select_business_level' | translate}} ---</option>
            <option *ngFor="let level of businessLevels" [value]="level.id">{{level.name}}</option>
          </select>
                    <div *ngIf="isSubmittingleveform && rflevel.assignBusinessLevel.invalid" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>
                        <small [hidden]="!rflevel.assignBusinessLevel.errors?.required">{{'business_level_error' | translate}}</small>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-sm-row flex-column justify-content-start mt-3">
                <button type="submit" class="btn btn-liked-you mr-sm-2 mb-1">Assign</button>
            </div>
        </form>
    </div>

</div>


<div id="couponModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
        <span class="close" (click)="closeModal()">&times;</span>
        <h3 class="modal-title">Add Image</h3>
        <div class="container" id="img-modal">
            <div class="row custom-wrapper">
                <div class="col-md-12">

                    <div>
                        <div class="group-gap">

                            <!-- Upload image -->
                            <div class="avatar-upload">
                                <div class="avatar-edit">
                                    <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="fileChangeEvent($event)" />
                                    <!-- <input *ngIf="croppedImage"  #fileInput (click)="uploadFileOnServer()" /> -->

                                </div>


                                <!-- <div class="avatar-preview">
                                    <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <!-- Buttons -->
                        <div class="d-flex flex-sm-row flex-column justify-content-start mt-3">
                            <button class="btn btn-liked-you mr-sm-2 mb-1" (click)="fileInput.click()">Browse</button>
                            <button class="btn btn-liked-you mr-sm-2 mb-1" (click)="closeModal()">Done</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<script>
    phone.addEventListener('keyup', function(e) {
        console.log(e.keyCode);
        if (e.keyCode !== 8) {
            if (this.value.length === 4 || this.value.length === 9 || this.value.length === 14) {
                this.value = this.value += ' ';
            }
        }
    });
</script>