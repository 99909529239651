import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[appAlphabetOnly]'
})

export class AlphabetOnlyDirective {
    key;
    @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
        this.key = event.key;
        const regex = /^[a-zA-Z ]*$/;
        // console.log(this.key);
        if (!this.key.match(new RegExp(regex, "i"))) {
            event.preventDefault();
            return false;
        }
    }
}
