import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from 'ng-click-outside';

import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes';

//COMPONENTS
import { NavbarComponent, FooterComponent, TableHeaderComponent, DateRangeComponent, DateRange2Component, OdometerComponent } from "./components";
import { HorizontalMenuComponent } from './horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { TopMenuAnchorToggleDirective, TopMenuDropdownDirective, TopMenuLinkDirective, TopMenuDirective, NumberDirective, AlphabetOnlyDirective,
SidebarDirective, SidebarAnchorToggleDirective, SidebarDropdownDirective, SidebarLinkDirective, ToggleFullscreenDirective } from "./directives";
 
import {NgbDateCustomParserFormatter} from './services';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { RatingComponent } from './components/rating/rating.component';
import {Ng2TelInputModule} from 'ng2-tel-input';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ImageCropperComponent } from './image-cropper/component/image-cropper/image-cropper.component';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { teamRatingComponent } from 'app/pages/full-pages/team-management/ratings/team.ratings.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { InstallOpenAppComponent } from 'app/pages/content-pages/install-open-app/install-open-app.component';
// import { InstallOpenAppComponent } from './install-open-app/install-open-app.component';

@NgModule({
    exports: [
        CommonModule,
        EditProfileComponent,
        RatingComponent,
        FooterComponent,
        NavbarComponent,
        TableHeaderComponent,
        ImageCropperComponent,
        ColorPickerModule,
        DateRangeComponent,
        DateRange2Component,
        VerticalMenuComponent,
        HorizontalMenuComponent,
		Ng2TelInputModule,
        CustomizerComponent,
        NotificationSidebarComponent,
        OdometerComponent,
        teamRatingComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NumberDirective,
        AlphabetOnlyDirective,
        NgbModule,
        TranslateModule,
		NgxSliderModule,
        PipeModule,
        InstallOpenAppComponent
       
        
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
		NgxSliderModule,
        TranslateModule,
        FormsModule,
        NgxDatatableModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        ColorPickerModule,
		Ng2TelInputModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        Ng2TelInputModule,
        
        
    ],
    declarations: [
        RatingComponent,
        EditProfileComponent,
        FooterComponent,
        ImageCropperComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        teamRatingComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        NumberDirective,
        AlphabetOnlyDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        TableHeaderComponent,
        DateRangeComponent,
        DateRange2Component,
        OdometerComponent,
        InstallOpenAppComponent
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ],
})
export class SharedModule { }
