import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
@Component({
	selector: 'app-install-open-app',
	templateUrl: './install-open-app.component.html',
	styleUrls: ['./install-open-app.component.scss']
})
export class InstallOpenAppComponent implements OnInit {

	deviceInfo: DeviceInfo;
	id: any;
	constructor(private deviceService: DeviceDetectorService, private window: Window, private activatedRoute: ActivatedRoute
	) { }

	ngOnInit() {
		this.deviceInfo = this.deviceService.getDeviceInfo();
		console.log('deviceInfo-------->>',this.deviceInfo);
		
		this.id = this.activatedRoute.snapshot.params.id

		// localStorage.setItem('qrId', JSON.stringify(this.id))
		//const userData = { qrId: `http://112.196.1.221://install-open-app/${this.id}` }
		// localStorage.setItem('userData', JSON.stringify(userData))
		if (this.deviceInfo.os == 'iOS') {
			setTimeout(() => {
				this.handler(this.id);
			}, 1);
		}
		if (this.deviceInfo.os == 'Android') {
			setTimeout(() => {
				this.handler2(this.id);
			}, 1);
		}
	}
	epicFunction() {
		console.log('hello `Home` component');
		this.deviceInfo = this.deviceService.getDeviceInfo();
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		const isDesktopDevice = this.deviceService.isDesktop();
		console.log(this.deviceInfo);
		console.log('isMobile: ', isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
		console.log('isTablet:', isTablet);  // returns if the device us a tablet (iPad etc)
		console.log('isDesktopDevice:', isDesktopDevice); // returns if the app is running on a Desktop browser.
	}
	handler(id: any) {
		setTimeout(function () {
			this.window.location = environment.siteUrl + `/mobile?qrId=${id}`;
		}, 25);
		this.window.location = `likedYouQrcodeScanned://install-open-app/${id}` as any;
	}

	handler2(id: any) {
		
		setTimeout(function () {
			this.window.location = environment.siteUrl + `/mobile?qrId=${id}`;
			// this.window.location = `https://app.liked-you.com/mobile?qrId=${id}`;
		}, 5);
		this.window.location = "https://play.google.com/store/apps/details?id=com.app.LikedYou" as any;
	}


}
