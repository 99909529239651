<!-- *NAVBAR* starts -->
<app-navbar (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>
<!-- *NAVBAR* ends -->

<div class="wrapper" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}" (click)="onWrapperClick();"
	(window:resize)="onResize($event)">

	<!-- *TOP* Menu starts -->
	<app-horizontal-menu appTopMenu *ngIf="menuPosition === 'Top' && !displayOverlayMenu"></app-horizontal-menu>
	<!-- *TOP* Menu ends -->

	<!-- *SIDE* Menu starts -->
	<div appSidebar *ngIf="menuPosition === 'Side' || displayOverlayMenu"
		[ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' || displayOverlayMenu, 'menu-native-scroll': !perfectScrollbarEnable }"
		class="app-sidebar" (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)"
		data-active-color="white" [attr.data-image]="bgImage">
		<!-- // #1e1532 -->
		<app-sidebar></app-sidebar>
		<div class="sidebar-background" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
			*ngIf="config?.layout.sidebar.backgroundImage"></div>
	</div>
	<!-- *SIDE* Menu ends -->

	<div class="main-panel">
		<button class="btn btn-liked-you scroll-down" type="button" *ngIf="isScrollDownVisible" (click)="scrollToDown()">
			<em class="ft-arrow-down"></em>
		</button>
		<div class="main-content">
			<div class="content-overlay"></div>
			<div class="content-wrapper">
				<breadcrumb #parent>
					<ol class="breadcrumb">
						<ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
							<!--IF CONDITIONS BECAUSE IN ADMIN NAD BO DASBOARD BREADCRUMBS CONTENT IS DIFFERENT-->
							<li *ngIf="!route.terminal" class="breadcrumb-item">
								<a href="" *ngIf="route.url == '/dashboard-management/dashboard'"
									[routerLink]="[route.url]">{{ route.displayName | dashboard | translate }}</a>
								<a href="" *ngIf="route.url != '/dashboard-management/dashboard'"
									[routerLink]="[route.url]">{{ route.displayName | translate }}</a>
							</li>
							<li *ngIf="route.terminal && route.url == '/dashboard-management/dashboard'"
								class="breadcrumb-item active" aria-current="page">{{ route.displayName | dashboard |
								translate}}</li>
							<li *ngIf="route.terminal && route.url != '/dashboard-management/dashboard'"
								class="breadcrumb-item active" aria-current="page">{{ route.displayName | translate}}
							</li>
						</ng-template>
					</ol>
				</breadcrumb>
				<router-outlet></router-outlet>
			</div>
		</div>
		<!-- FOOTER -->
		<!-- <app-footer></app-footer> -->
		<!-- Scroll to top button -->
		<button class="btn btn-liked-you scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()">
			<em class="ft-arrow-up"></em>
		</button>
	</div>

	<!-- *NOTIFICATION-SIDEBAR* starts -->
	<app-notification-sidebar></app-notification-sidebar>
	<!-- *NOTIFICATION-SIDEBAR* ends -->

	<!-- *CUSTOMIZER* starts -- Uncomment below code if you want to use customizer in your project -->
	<!-- <app-customizer></app-customizer> -->
	<!-- *CUSTOMIZER* ends -->

	<div class="sidenav-overlay" (click)="onOutsideClick($event);"
		[ngClass]="{'d-none': displayOverlayMenu && hideSidebar && !overlayContent , 'd-block': displayOverlayMenu && !hideSidebar && overlayContent && innerWidth < 1200}">
	</div>
	<div class="drag-target"></div>
</div>