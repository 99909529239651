<!--Login Page Starts-->
<section id="login">
	<div class="row auth-height full-height-vh m-0">
		<div class="col-12 d-flex align-items-center justify-content-center">
			<div class="card overflow-hidden">
				<div class="card-content">
					<div class="card-body auth-img">
						<div class="row m-0">
							<div class="col-lg-12  d-lg-flex justify-content-center align-items-center auth-img-bg p-3">	
								
								<div class="col-12  d-flex flex-column align-items-center justify-content-center">
											
									<!-- <div style="font-size: 15px;" class="mb-3 text-center text-md-left">
										Download liked-you! im App Store
									</div> -->
								
								
                                <!-- <a target="blank" href="https://itunes.apple.com/us/app/appname/com.app.LikedYou"> -->
									<!-- <img *ngIf="deviceInfo.os == 'iOS'" src="assets/img/gallery/apple.png" alt="ios" (click)="handler()" class="img-fluid" width="300" height="230"> -->
								<!-- </a> -->
								<!-- https://play.google.com/store/apps/details?id=com.example.LikedYou -->
								<!-- <a target="blank" href="https://play.google.com/store/apps/details?id=com.app.LikedYou"> -->
								<!-- <img *ngIf="deviceInfo.os == 'Android'" src="assets/img/gallery/gplay.jpg" alt="googleplay" (click)="handler2()" class="img-fluid" width="300" height="230"> -->
								<!-- </a> -->
							</div>
                            </div>
                            
							<!-- <div class="col-lg-6 col-12 px-4 py-3">
                                <h4 class="mb-2 card-title">Device info</h4>	
								<div *ngIf="deviceInfo">
									<ul>
										<li>DEVICE : {{deviceInfo.device}}</li>										
										<li>OS : {{deviceInfo.os}}</li>										
									</ul>
								</div>																							
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--Login Page Ends-->

