import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
	providedIn: 'root'
})
export class ToastService {
	private toastrConfig: Partial<IndividualConfig> = {
		progressBar: true,
		timeOut: 4000,
		tapToDismiss: true,
		closeButton: true
	}
	successString = 'Success!'
	errorString = 'Error!'
	warningString = 'Warning!'

	constructor(public toastr: ToastrService, public translate: TranslateService) {
		this.successString = this.translate.instant('success')
		this.errorString = this.translate.instant('error')
		this.warningString = this.translate.instant('warning')
	}

	public success(msg: string, title: string = this.successString): void {
		this.toastr.success(msg, title, this.toastrConfig);
	}
	public success2(msg: string, title: string = ''): void {
		this.toastr.success(msg, title, this.toastrConfig);
	}
	public error(msg: string, title: string = this.errorString): void {
		this.toastr.error(msg, title, this.toastrConfig);
	}

	public warning(msg: string, title: string = this.warningString): void {
		this.toastr.warning(msg, title, this.toastrConfig);
	}
}
