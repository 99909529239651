<div class="input-group mb-0 date-range">
	<span class="mr-1 d-flex align-items-center">From:</span>
	<input 
		class="form-control" 
		placeholder="From"
		[(ngModel)]="fromDate"
		[readonly]="true"
		(ngModelChange)="onDateChange('from')"
		ngbDatepicker #d1="ngbDatepicker"
		[maxDate]="fromMax"
	>
	<div class="input-group-append">
		<button class="btn btn-outline-secondary calendar px-1" (click)="d1.toggle()" type="button">
			<em class="fa fa-calendar"></em>
		</button>
	</div>
	<span class="mx-1 d-flex align-items-center">To:</span>
	<input 
		class="form-control" 
		placeholder="To"
		[(ngModel)]="toDate"
		[readonly]="true"
		(ngModelChange)="onDateChange('to')"
		ngbDatepicker #d2="ngbDatepicker"
		[maxDate]="toMax"
		[minDate]="toMin"
	>
	<div class="input-group-append">
		<button class="btn btn-outline-secondary calendar px-1" (click)="d2.toggle()" type="button">
			<em class="fa fa-calendar"></em>
		</button>
	</div>
</div>