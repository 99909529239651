import { environment } from '../../../environments/environment';

export class ApiRoutes {
    // api base url
    public static apiBaseUrl: string = environment.apiBaseUrl + '/'
    public static imgBaseUrl: string ='https://dpvfpgxaknlfm.cloudfront.net/'
    // dashboard
    public static get getDashboardSP(): string { return this.apiBaseUrl + 'dashboard/list'; }
    public static get getOnboarding(): string { return this.apiBaseUrl + 'dashboard/sponboard' }
    public static get sendOnboardingVal(): string { return this.apiBaseUrl + 'dashboard/useronboardcheck' }
    public static get spExcelDownload(): string { return this.apiBaseUrl + 'dashboard/downloadExcel'; }
    public static get getSPTeams(): string { return this.apiBaseUrl + 'dashboard/spTeamList'; }
    public static get getBOCategories(): string { return this.apiBaseUrl + 'dashboard/listCategories'; }
    public static get getCSV():string { return this.apiBaseUrl+'offer/getOfferHistory';}
    public static get SPSingleRatingComparison() : string { return this.apiBaseUrl+'rating/dashboardUserAvgRating'}
    public static get SPTeamRatingComparison() : string { return this.apiBaseUrl+'rating/dashboardTeamAvgRating'}
    public static get SPXLSXdownload() : string { return this.apiBaseUrl+'dashboard/listAll'}
    public static get TeamXLSXdownload() : string { return this.apiBaseUrl+'team/listAll'}
    public static get statistics() : string {return this.apiBaseUrl+'dashboard/statistics'}
    // public static get userStatsDownload(): string { return this.apiBaseUrl + 'dashboard/userReport'; }

    // user
    public static get login(): string { return this.apiBaseUrl + 'user/login'; }
    public static get businessOwnerLogin(): string { return this.apiBaseUrl + 'user/businessOwnerLogin'; }
    public static get register(): string { return this.apiBaseUrl + 'user/register'; }
    public static get tokenVerification(): string { return this.apiBaseUrl + 'user/verifyToken'; }
    public static get businessOwnerSetPassword(): string { return this.apiBaseUrl + 'user/setPassword'; }
    public static get resetPassword(): string { return this.apiBaseUrl + 'user/changePassword'; }
    public static get resetMasterPassword(): string { return this.apiBaseUrl+ 'user/setMasterPassword'}
    public static get listBusinessOwners(): string { return this.apiBaseUrl + 'user/list'; }
    public static get updateBusinessOwner(): string { return this.apiBaseUrl + 'user/update'; }
    public static get updateLogo(): string { return this.apiBaseUrl + 'user/update_logo/'; }
    public static get viewBusinessOwner(): string { return this.apiBaseUrl + 'user/view'; }
    public static get forgotPassword(): string { return this.apiBaseUrl + 'user/forgot_password'; }
    public static get checkExpireResetPass(): string { return this.apiBaseUrl + 'tokenVerification'; }
    public static get appRoute() : string { return this.apiBaseUrl+'apple-app-site-association'}
    public static get BOapprovalByEamil() : string { return this.apiBaseUrl+'user/updateApprovalviaemail'}
    public static get checkBOapprovalAlreadyDoneOrNot() : string { return this.apiBaseUrl+'user/checkRequestStatus'}

    // business-level
    public static get addBusinessLevel(): string { return this.apiBaseUrl + 'businessLevel/add'; }
    public static get getBusinessLevel(): string { return this.apiBaseUrl + 'businessLevel/view'; }
    public static get updateBusinessLevel(): string { return this.apiBaseUrl + 'businessLevel/update'; }
    public static get getBusinessLevels(): string { return this.apiBaseUrl + 'businessLevel/list'; }
    public static get deleteBusinessLevel(): string { return this.apiBaseUrl + 'businessLevel/delete'; }

    // service-provider
    public static get addServiceProvider(): string { return this.apiBaseUrl + 'serviceProvider/add'; }
    public static get spTokenVerification(): string { return this.apiBaseUrl + 'serviceProvider/verifyToken'; }
    public static get getServiceProvider(): string { return this.apiBaseUrl + 'serviceProvider/getDetails'; }
    public static get getSPRatinglist(): string { return this.apiBaseUrl + 'serviceProvider/getRatinglist'; }

    public static get spRatingDetails(): string { return this.apiBaseUrl + 'serviceProvider/guestListDetail'; }

    public static get updateServiceProvider(): string { return this.apiBaseUrl + 'serviceProvider/update'; }
    public static get updateSPBlockStatus(): string { return this.apiBaseUrl + 'serviceProvider/updateBlockStatus'; }
    public static get getServiceProviders(): string { return this.apiBaseUrl + 'serviceProvider/list'; }
    public static get getAvailableServiceProviders(): string { return this.apiBaseUrl + 'serviceProvider/available'; }
    public static get deleteServiceProvider(): string { return this.apiBaseUrl + 'serviceProvider/delete'; }
    public static get createServiceProviderPassword(): string { return this.apiBaseUrl + 'serviceProvider/createPassword'; }
    public static get downloadSpProfile(): string {return this.apiBaseUrl +'serviceProvider/downloadSPprofile';}
    // public static get getSPTeams(): string { return this.apiBaseUrl + 'serviceProvider/teamListAll'; }

    public static get getRatingListAll(): string { return this.apiBaseUrl + 'serviceProvider/guestListAll'; }
    // team
    public static get addSTeam(): string { return this.apiBaseUrl + 'team/add'; }
    public static get getSTeam(): string { return this.apiBaseUrl + 'team/view'; }
    public static get updateSTeams(): string { return this.apiBaseUrl + 'team/update'; }
    public static get updateSTeamStatus(): string { return this.apiBaseUrl + 'team/status'; }
    public static get getSTeams(): string { return this.apiBaseUrl + 'team/list'; }
    public static get getSTeamMembers(): string { return this.apiBaseUrl + 'team/listMembers'; }
    public static get deleteSTeam(): string { return this.apiBaseUrl + 'team/delete'; }
    public static get teamRatings(): string { return this.apiBaseUrl + 'team/listRating';}
    
    // guest-user
    public static get guestResetPassword(): string { return this.apiBaseUrl + 'guestUser/resetPassword'; }
    public static get guestAccountVerify(): string { return this.apiBaseUrl + 'guestUser/verifyAccount'; }
    public static get guestForgotPassword(): string { return this.apiBaseUrl + 'guestUser/forgotPassword'; }

    // industry-type
    public static get addIndustry(): string { return this.apiBaseUrl + 'industryType/add'; }
    public static get getIndustry(): string { return this.apiBaseUrl + 'industryType/view'; }
    public static get updateIndustry(): string { return this.apiBaseUrl + 'industryType/update'; }
    public static get getIndustries(): string { return this.apiBaseUrl + 'industryType/list'; }
    public static get deleteIndustry(): string { return this.apiBaseUrl + 'industryType/delete'; }

    // service-person-category
    public static get addCategory(): string { return this.apiBaseUrl + 'servicePersonCategory/add'; }
    public static get getCategory(): string { return this.apiBaseUrl + 'servicePersonCategory/view'; }
    public static get updateCategory(): string { return this.apiBaseUrl + 'servicePersonCategory/update'; }
    public static get updateCategoryBlockStatus(): string { return this.apiBaseUrl + 'servicePersonCategory/updateBlockStatus'; }
    public static get getCategories(): string { return this.apiBaseUrl + 'servicePersonCategory/list'; }
    public static get deleteCategory(): string { return this.apiBaseUrl + 'servicePersonCategory/delete'; }

    // designation
    public static get addDesignation(): string { return this.apiBaseUrl + 'designation/add'; }
    public static get getDesignation(): string { return this.apiBaseUrl + 'designation/view'; }
    public static get updateDesignation(): string { return this.apiBaseUrl + 'designation/update'; }
    public static get updateDesignationStatus(): string { return this.apiBaseUrl + 'designation/updateBlockStatus'; }
    public static get getDesignations(): string { return this.apiBaseUrl + 'designation/list'; }
    public static get deleteDesignation(): string { return this.apiBaseUrl + 'designation/delete'; }

    // rating-question
    public static get addQuestion(): string { return this.apiBaseUrl + 'ratingQuestion/add'; }
    public static get getQuestion(): string { return this.apiBaseUrl + 'ratingQuestion/view'; }
    public static get updateQuestion(): string { return this.apiBaseUrl + 'ratingQuestion/update'; }
    public static get getQuestions(): string { return this.apiBaseUrl + 'ratingQuestion/list'; }
    public static get deleteQuestion(): string { return this.apiBaseUrl + 'ratingQuestion/delete'; }

    //rating-labels
    public static get addlabels() : string { return this.apiBaseUrl+ 'ratingLabel/add';}
    public static get getLabels() : string {return this.apiBaseUrl+'ratingLabel/list'}
    public static get deleteLabels() : string  {return this.apiBaseUrl+'ratingLabel/delete'}
    public static get updateLables() : string { return this.apiBaseUrl+'ratingLabel/update'}

    
    // qr-code
    public static get generateQRCodes(): string { return this.apiBaseUrl + 'qrCode/add'; }
    public static get getMappedQR(): string { return this.apiBaseUrl + 'qrCode/mapRec'; }
    public static get getUnmappedQR(): string { return this.apiBaseUrl + 'qrCode/unmapRec'; }
    public static get mapQrCode(): string { return this.apiBaseUrl + 'qrCode/mapping'; }
    public static get getAllQR(): string { return this.apiBaseUrl + 'qrCode/list'; }
    public static get getFilteredQR() : string { return this.apiBaseUrl + 'qrCode/businessOwner/' ; }
    public static get getQrForStickers() : string { return this.apiBaseUrl + 'qrCode/printQrCode'}
    public static get getQrForCards() : string { return this.apiBaseUrl + 'qrCode/printQrCode_2'}
    public static get getQRCodeDownload() : string { return this.apiBaseUrl +'qrCode/downloadQrCode'}
    public static get getBussinessQRcode() : string { return this.apiBaseUrl + 'qrCode/businessList'}
    public static get addQRCodeForBussiness() : string { return this.apiBaseUrl + 'user/generateQrCode'}
    //download rating exl
    public static get downloadExl() : string { return this.apiBaseUrl+'rating/ratingAnswerList'}
    
    public static get multiQuestionExl() : string { return this.apiBaseUrl+'rating/downloadAllQuestion'}
    public static get downloadAllQuestionforAdmin() : string { return this.apiBaseUrl+'rating/downloadAllQuestionForAdmin'}
    

    public static get multiQuestionExlforAdmin() : string { return this.apiBaseUrl+'dashboard/single-rating-download'}
    public static get multiQuestionExlforTeamAdmin() : string { return this.apiBaseUrl+'dashboard/team-rating-download'}
    // offer
    public static get addOffer(): string { return this.apiBaseUrl + 'offer/add'; }
    public static get addCustomOffer(): string { return this.apiBaseUrl + 'offer/addCustom'; }
    public static get getOffer(): string { return this.apiBaseUrl + 'offer/view'; }
    public static get updateOffer(): string { return this.apiBaseUrl + 'offer/update'; }
    public static get updateOfferStatus(): string { return this.apiBaseUrl + 'offer/updateBlockStatus'; }
    public static get getOffers(): string { return this.apiBaseUrl + 'offer/list'; }
    public static get getOfferRedeemedHistory(): string { return this.apiBaseUrl + 'offer/getRedeemedHistory'; }
    public static get deleteOffer(): string { return this.apiBaseUrl + 'offer/delete'; }

    // informative-pages
    public static get addInformative(): string { return this.apiBaseUrl + 'informative/add'; }
    public static get getInformative(): string { return this.apiBaseUrl + 'informative/view'; }
    public static get updateInformative(): string { return this.apiBaseUrl + 'informative/update'; }

    // faq
    public static get addFaq(): string { return this.apiBaseUrl + 'faq/add'; }
    public static get getFaq(): string { return this.apiBaseUrl + 'faq/view'; }
    public static get updateFaq(): string { return this.apiBaseUrl + 'faq/update'; }
    public static get getFaqCategories(): string { return this.apiBaseUrl + 'faq/listCategories'; }
    public static get deleteFaq(): string { return this.apiBaseUrl + 'faq/delete'; }

    // Mobile-Browser
    public static get registerwithemail(): string { return this.apiBaseUrl + 'guestUser/webUserRegistration';}
    public static get findSp():string { return this.apiBaseUrl + 'qrCode/findServiceProvider';}
    public static get addRating():string { return this.apiBaseUrl + 'rating/add';}
    public static get SpCategory():string { return this.apiBaseUrl + 'ratingLabel/servicePersonCategory';}
    public static get findQuestion(): string { return this.apiBaseUrl + 'ratingQuestion/findQuestion';}
    public static get questionCheck(): string { return this.apiBaseUrl + 'ratingQuestion/checkQuestion';}
    public static get voucherCoupon(): string { return this.apiBaseUrl + 'offer/serviceProviderOffer'}
    public static get singleRating(): string { return this.apiBaseUrl + 'rating/singleRating'}
    public static get mbResetPassword(): string { return this.apiBaseUrl + 'guestUser/resetPassword'; }
    public static get sendVerificationMail(): string { return this.apiBaseUrl + 'guestUser/sendVerificationMail'; }
    public static get sendVoucherMail(): string { return this.apiBaseUrl + 'guestUser/sendVoucherMail'; }



   

    
}
