import { Component, OnInit, ViewChild, TemplateRef, Input, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { triangleBall, columns } from '../../utility';
import { DataService, SwalService, ToastService } from '../../services';
import { APP_ROUTES, ApiRoutes } from '../../routes';
import { IBusinessLevel, IBusinessOwners, IIndustry } from 'app/interfaces';
import { AuthService } from 'app/shared/auth';
import { SweetAlertType } from 'sweetalert2';
import { LocalStorage } from '../../../storage';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ImageCroppedEvent } from 'app/shared/image-cropper/interfaces';
import { ImageCropperComponent } from 'app/shared/image-cropper/component/image-cropper/image-cropper.component';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit ,AfterViewInit{
	@Input() title: string;
	@Input() profile: boolean;
	@Input() id: any;
	@ViewChild('phoneInput')phoneInput: ElementRef;
  	isSubmitting: boolean = false;isSubmittingleveform: boolean = false;
    APP_ROUTES = APP_ROUTES;
	editBusinessOwnerForm: FormGroup;
	public businessLevels: IBusinessLevel[] = [];
	public industryTypes: IIndustry[] = [];
	user
	emailCheck : Boolean = false;
	updatingBusinessOwnerId = null;
	assignLevelForm: FormGroup;
	levelModal = null;
	public temp: IBusinessOwners[] = [];
	public rows: IBusinessOwners[] = [];
	imageuploaded:Boolean = true;
	isLogo: Boolean = false;
	showimage;
	fileSizeError : Boolean = false;
	width:any;
	height:any;
	size:any;
	imageUrl: any = './assets/img/photos/unnamed.png'; ;
	imageFile = null;
    isImageUrlNull ;
	loggedInUser:any;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	showCropper = false;
	routeBack:boolean;
	addedBy:any;

	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	constructor(private sanitizer:DomSanitizer , private toastr: ToastService, private ds: DataService, private swal: SwalService , private router: Router, private spinner: NgxSpinnerService, private route: ActivatedRoute, public auth: AuthService,private ls:LocalStorage) {
		this.initForm();
		this.initAssignLevelForm();
		console.log("showimage",this.showimage)
		const showModel = this.router.getCurrentNavigation().extras.state;
		this.routeBack=showModel?.routeBack;
		this.addedBy= showModel?.addedBy;
	}
	
	ngOnInit(): void {
		this.getIndustryTypes();
		this.getBusinessLevels();
		//this.getBusinessOwners();
		setTimeout(() => {
			this.getUserDetails();
		});
	
		this.levelModal = document.getElementById("levelFormModal");
		window.onclick = function(event) {
			if (event.target == this.levelModal) {
				this.levelModal.style.display = "none";
			}
		}

	}
	// Close Modal
	closeModal() {
		this.levelModal.style.display = 'none';
	}

	get rf() {
		return this.editBusinessOwnerForm.controls;
	}

	private initForm(): void {
		this.editBusinessOwnerForm = new FormGroup({
			id: new FormControl(''),
			businessName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
			salutation: new FormControl('', [Validators.required]),
			firstName: new FormControl('', [Validators.required]),
			lastName: new FormControl('', [Validators.required]),
			// contactNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]),
			contactNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9 +()]*$/)]),
			//contactNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{4}[\s][0-9]{3}[\s][0-9]{4,6}$/)]),

			addressOfBusiness: new FormControl('', [Validators.required]),
			city: new FormControl('', [Validators.required]),
			// streetNumber: new FormControl(null, [Validators.pattern(/^[0-9]\d*$/)]),
			streetNumber: new FormControl(null),
			postalCode: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]),
			country: new FormControl('', [Validators.required]),
			businessCategory: new FormControl('', [Validators.required]),
			industryType: new FormControl('', [Validators.required]),
			businessLevel: new FormControl('', [Validators.required])
		}, { updateOn: 'blur' });
	}


	
	// Get User Details
	private getUserDetails(loaderFlag: boolean = true): void {
		let token = JSON.parse(localStorage.getItem('likedyou-currentUser-'+`${localStorage.getItem('token')}`))
		// this.emailCheck = token.IndustryType.email
		// console.log(token)
		const url: string = ApiRoutes.viewBusinessOwner + APP_ROUTES.slash + this.id;
		this.ds.spinnerTxt = '';
		this.croppedImage = ''
		this.spinner.show(undefined, triangleBall)
		this.ds.get(url).subscribe((res) => {
			this.ds.spinnerTxt = '';
			this.spinner.hide();
			if(res.success) {
				this.user = res.data;///console.log("this"+JSON.stringify(this.user))
				//console.log(this.user.contactNumber)
				
				if(this.user.logo !="" && this.user.logo != null){
					this.isLogo = true;
					this.showCropper = false;
					this.showimage = ApiRoutes.imgBaseUrl+this.user.logo
				}
				// this.user.contactNumber = this.user.contactNumber.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'')

				if(this.user.email == token.email){
					this.emailCheck = true
				}
				
				this.editBusinessOwnerForm.patchValue({
					id: this.id,
					businessName: this.user.businessName,
					email: this.user.email,
					salutation: this.user.salutation,
					firstName: this.user.firstName,
					lastName: this.user.lastName,
					contactNumber: this.user.contactNumber,//.slice(0, 4) + " " + this.user.contactNumber.slice(4),
					addressOfBusiness: this.user.addressOfBusiness,
					city: this.user.city,
					streetNumber: this.user.streetNumber,
					postalCode: this.user.postalCode,
					country: this.user.country,
					businessCategory: this.user.businessCategory,
					industryType: this.user.industryType,
					businessLevel: this.user.businessLevel
				});
				this.phoneInput.nativeElement.dispatchEvent(
					new KeyboardEvent('keyup', { bubbles: true })
				  );
			}
		}, error => {
			this.ds.spinnerTxt = '';
			this.spinner.hide();
		});
	}

	// Get Industry Types
	private getIndustryTypes(loaderFlag: boolean = true): void {
		const url: string = ApiRoutes.getIndustries;
		// this.ds.spinnerTxt = '';
		// this.spinner.show(undefined, triangleBall)
		this.ds.post(url,{}).subscribe((res) => {
			if(res.success) {
				this.industryTypes = res.data.industries;
			}
		}, error => {
			// this.ds.spinnerTxt = '';
			// this.spinner.hide();
		});
	}

	

	// Get Business Levels
	private getBusinessLevels(loaderFlag: boolean = true): void {
		const url: string = ApiRoutes.getBusinessLevels;
		// this.ds.spinnerTxt = '';
		// this.spinner.show(undefined, triangleBall)
		const payload = {
			addedBy: this.profile && this.auth.actualAddedBy == 1 ? 1 : this.auth.addedBy,
			lang:localStorage.getItem('locale')
		}
		this.ds.post(url, payload).subscribe((res) => {
			if(res.success) {
				//this.businessLevels = res.data.businessLevels;

				if(this.auth.role == 'admin'){		
					// for(let i of res.data.businessLevels){
					// 	this.businessLevels.push({"name":i.name,id:i.id});
					// }			
					this.businessLevels = res.data.businessLevels.filter((level)=>{
						return level.name.includes('Level 1');
					})
				}else{
					if(this.auth.businessLevel.name.includes('Level 1')){
						this.businessLevels = res.data.businessLevels.filter((level)=>{
							return level.name.includes('Level 2');
						})
					}
					if(this.auth.businessLevel.name.includes(`Level 2`)){
						this.businessLevels = res.data.businessLevels.filter((level)=>{
							return level.name.includes('Level 3');
						})
					}
				}				
				//this.businessLevels = res.data.businessLevels;
				// console.log(this.businessLevels)
				// if(this.businessLevels.length == 1){
				// 	console.log(this.businessLevels)
				// 	this.editBusinessOwnerForm.controls['businessLevel'].setValue(this.businessLevels)
				// }
				
			}
		}, error => {
			// this.ds.spinnerTxt = '';
			// this.spinner.hide();
		});
	}

	// On submit button click
	onSubmit(): void {
		this.uploadFileOnServer();

		// let newContact = this.editBusinessOwnerForm.value.contactNumber.split(" ").join("")
		// console.log(this.editBusinessOwnerForm.value.contactNumber.split(" ").join(""))
		// this.editBusinessOwnerForm.controls['contactNumber'].setValue(this.editBusinessOwnerForm.value.contactNumber.split(" ").join(""))
	let val = (<HTMLInputElement>document.getElementById('phonee')).value ;
	this.editBusinessOwnerForm.controls['contactNumber'].setValue(val)

		// console.log(this.user.contactNumber.slice(4,5))
		// this.editBusinessOwnerForm.patchValue({contactNumber: this.user.contactNumber.slice(4,5)}) 
		if (this.editBusinessOwnerForm.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.ds.spinnerTxt = '';
		this.spinner.show(undefined, triangleBall)
		const url: string = ApiRoutes.updateBusinessOwner;
		// if(Array.isArray(this.editBusinessOwnerForm.value.businessLevel)){
		// 	console.log(this.editBusinessOwnerForm.value)
		// 	this.editBusinessOwnerForm.value.businessLevel = this.editBusinessOwnerForm.value.businessLevel[0].id
		// }

		console.log(this.editBusinessOwnerForm.value)
		this.ds.put(url, this.editBusinessOwnerForm.value).subscribe(res => {
			this.ds.spinnerTxt = '';
			// this.spinner.hide();
			if(res.success) {
				let string = 'likedyou-currentUser-businessName';
				this.ls.setItem(string,this.editBusinessOwnerForm.value.businessName);				
				
				if(this.profile) {
					if(this.routeBack){
						this.sendOnboardValue();
						this.router.navigate([APP_ROUTES.dashboardManagenet + APP_ROUTES.slash +APP_ROUTES.dashboard]);
					}
					// this.router.navigate([APP_ROUTES.dashboard]);
					// window.location.reload();
                    this.getUserDetails();
					
				} else {
					if(this.user.status === 'verified') {
						this.router.navigate([APP_ROUTES.BOManagement +'/'+ APP_ROUTES.existingBusinessOwners]);
					} else if(this.user.status === 'unverified') {
						this.router.navigate([APP_ROUTES.BOManagement +'/' + APP_ROUTES.requestedBusinessOwners]);
					} else {
						this.router.navigate([APP_ROUTES.BOManagement +'/' +APP_ROUTES.rejectedBusinessOwners]);
					}
				}
			}
		}, error => {
			this.ds.spinnerTxt = '';
			this.spinner.hide();
		});
	}

	telInputObject(obj) {
		// console.log("telInput",obj);
		//obj.setCountry('in');
	}
	onCountryChange($event){}
	hasError($event){}
	getNumber($event){
		console.log($event)
	}

	sendOnboardValue(){
		const url=ApiRoutes.sendOnboardingVal
		let data={
			"userProfile": 1
		}
		this.ds.post(url+ `/${this.addedBy}`,data).subscribe((res)=>{
		})	
	   }

	public action(row: any, action: string, value: string): void {
		// console.log(row)
		// console.log(this.rf.businessLevel.invalid)
		if(action==='approve'){
			if (this.rf.businessLevel.invalid) {
				this.isSubmitting = true;
				return;
			}
		}
		
		let title: string = "Bist du sicher, dass du das willst " + action + " " + row.firstName + " " + row.lastName + "'s Anfrage?";
		if(localStorage.getItem('locale')=='en'){
			 title = "Are you sure you want to " + action + " " + row.firstName + " " + row.lastName + "'s request?";
		}
		const text: string = '';
		const confirmButtonText: string = 'Yes';
		const cancelButtonText: string = 'Cancel';
		const type: SweetAlertType = 'warning';
		this.swal.confirmAlert(title, text, confirmButtonText, cancelButtonText, type).then(data => {
			if (data.value) { console.log(data)
				this.updatingBusinessOwnerId = row.id
				if(action==='approve') {
					if(row.businessLevel){
						//console.log("dfdfdf",action)
						this.updateBusinessOwner('verified');
					} else {
						//console.log("in block")
						this.getBusinessLevels();
						this.updateBusinessOwner('verified');
						//this.levelModal.style.display = 'block';
					}
				} else {
					this.updateBusinessOwner('rejected');
				}
			}
		});
	}
	private initAssignLevelForm(): void {
		this.assignLevelForm = new FormGroup({
			assignBusinessLevel: new FormControl('', [Validators.required])
		}, { updateOn: 'blur' });
	}
	get rflevel() {
		return this.assignLevelForm.controls;
	}
	private getBusinessOwners(loaderFlag: boolean = true): void {
		const url: string = ApiRoutes.listBusinessOwners;
		let pagePayload: any = {
			page_no: 1,
			page_length: 10,
			search: ''
		}
		pagePayload.status = 'unverified';
		pagePayload.addedBy = this.auth.addedBy;
		if (loaderFlag) {
			this.spinner.show(undefined, triangleBall);
		}
		this.ds.post(url, pagePayload).subscribe((res) => {			
			this.hideLoader(loaderFlag);
			res.data.users.map(i => {
				i.businessLevel = i.Businesslevel && i.Businesslevel.name;
				return i;
			});
			this.temp = res.data.users;						
		}, err => {
			this.hideLoader(loaderFlag);
		});
	}
	updateBusinessOwner(action) {
		this.spinner.show(undefined, triangleBall);
		let bussinessLevelID : any;
		this.businessLevels.forEach(data=>{	
			if(data.name ==  this.editBusinessOwnerForm.value.businessLevel){
				bussinessLevelID = data.id;
			}
		})
		this.editBusinessOwnerForm.value.businessLevel = bussinessLevelID;
		const url: string = ApiRoutes.updateBusinessOwner;
		let payload: any = { 
			id: this.updatingBusinessOwnerId,
			status: action,
			// businessLevel: this.editBusinessOwnerForm.get('businessLevel').value 
			businessLevel : bussinessLevelID 
		};
		// console.log(JSON.stringify(payload))
		this.ds.put(url, payload).subscribe((res) => {
			this.hideLoader(true);
			if (res.success) {
				// const index: number = this.temp.findIndex(i => i.id === this.updatingBusinessOwnerId);
				// if (index > -1) {
				// 	this.temp.splice(index, 1);
				// 	this.rows = [...this.temp];					
				// }
				this.router.navigate([APP_ROUTES.BOManagement + '/' + APP_ROUTES.existingBusinessOwners]);
			}
		});
	}

	private hideLoader(loaderFlag: boolean): void {
		if (loaderFlag) {
			this.spinner.hide();
		}
	}

	// On submit button click
	onModalSubmit(): void {
		if (this.assignLevelForm.invalid) {
			this.isSubmittingleveform = true;
			return;
		}
		this.isSubmittingleveform = false;
		this.closeModal();
		this.updateBusinessOwner('verified');
	}


	// uploadFile(event) {
	  
	// 	let reader = new FileReader(); // HTML5 FileReader API
	// 	let file = event.target.files[0];
	// 	if (event.target.files && event.target.files[0]) {
	// 	  let image:any = event.target.files[0];
	// 	  this.size = image.size;
	// 	  let fr = new FileReader();
	// 	  fr.onload = () => { // when file has loaded
	// 	   var img = new Image();
	// 	   img.onload = () => {
	// 		   this.width = img.width;
	// 		   this.height = img.height;
	// 		   console.log("aa rha hai",this.width,this.height)
	// 		  //  if(this.width <= 512 && this.height <= 512){
	// 		  // 	console.log("aa rha hai",this.width,this.height)
	// 		  // 	return this.fileSizeError = true;
				  
				  
	// 		  //  }
	// 	   };
	   
	// 	  }
  
	// 	  //   if (event.target.files[0].size < 1300 * 800) {/* Checking height * width*/ return console.log("1200*1200")}
	// 	  // 	if (event.target.files[0].size < 2000000) {/* checking size here - 2MB */ }
	// 		  //console.log("600*600")
	// 	}
	// 	if (event.target.files && event.target.files[0]) {
	// 	  this.spinner.show(undefined, triangleBall);
	// 	  this.imageFile = file;
	// 	  var allowedExtension = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];
	// 	  const fileExtension = file.name.split('.').pop().toLowerCase();
	// 	  var isValidFile = false;
	// 	  for(var index in allowedExtension) {
	// 		  if(fileExtension === allowedExtension[index]) {
	// 			  isValidFile = true; 
	// 			  break;
	// 		  }
	// 	  }
	// 	  if(!isValidFile) {
	// 		  this.toastr.error('Allowed Extensions are : *.' + allowedExtension.join(', *.'))
	// 		  return;
	// 	  }
	// 	  reader.readAsDataURL(file);
		  
	// 	  // When file uploads
	// 	  reader.onload = () => {			
	// 		this.imageUrl = reader.result; 
	// 		this.imageuploaded = true
	// 		this.showimage = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageUrl);
	// 		this.spinner.hide();
	// 		const el: any = document.getElementById('img-modal');
	// 		el.click();
	// 		this.uploadFileOnServer()
	// 	  };
	// 	} 
	//   }

	uploadFileOnServer(){
		this.spinner.show(undefined, triangleBall)
		this.isSubmitting = false;
		this.ds.spinnerTxt = '';
		let formData  = new FormData()
		// if(this.imageFile) {
		// 	formData.append('logo', this.imageFile);
		// }
		if(this.croppedImage) {
				formData.append('logo', this.getBlob(this.croppedImage));
		}
		const url: string = ApiRoutes.updateLogo+this.id;
		this.ds.post(url, formData).subscribe(res => {
			this.ds.spinnerTxt = '';
			this.spinner.hide();
			if(res.success) {
				this.getUserDetails();
				// let string = 'likedyou-currentUser-businessName';
				// this.ls.setItem(string,this.editBusinessOwnerForm.value.businessName);				
			}
		}, error => {
			console.log(error)
			this.ds.spinnerTxt = '';
			this.spinner.hide();
		});
	}

	public ngAfterViewInit(): void{
		if (this.phoneInput) {
		  fromEvent(this.phoneInput.nativeElement, 'keydown').pipe(debounceTime(200), map(x => x['target']['value'])).subscribe(value => {
		   if(value.length  == 4){
			 (<HTMLInputElement>document.getElementById('phonee')).value = value+" ";
		   }
		   else if(value.length > 4 && value.length <= 8){
				   value = value.substring(0,4)+" "+value.substring(5)+" " ;        
				   (<HTMLInputElement>document.getElementById('phonee')).value = value;
		   }
		   else if(value.length > 8){
			let val = value;
			   if(value.includes(" ")){
				   value = value.replace(/\s/g, "")
			   }
			   value = value.substring(0,4)+" "+value.substring(4,7)+" "+value.substring(7) ;    
			   (<HTMLInputElement>document.getElementById('phonee')).value = value;
		   }
			  
		  });
	}}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		console.log(this.croppedImage)
		//   console.log(event);
	}
	imageLoaded() {
		console.log('image loadoed')
		this.showCropper = true;
		console.log('showcropper '+this.showCropper)
	}
	cropperReady() {
	}
	loadImageFailed() {
	}
	rotateLeft() {
		this.imageCropper.rotateLeft();
	}
	rotateRight() {
		this.imageCropper.rotateRight();
	}
	flipHorizontal() {
		this.imageCropper.flipHorizontal();
	}
	flipVertical() {
		this.imageCropper.flipVertical();
	}

	getBlob(image) {
		let dataURL1 = image;
		const data = atob(dataURL1.substring('data:image/png;base64,'.length)),
			asArray = new Uint8Array(data.length);

		for (var i = 0, len = data.length; i < len; ++i) {
			asArray[i] = data.charCodeAt(i);
		}

		const blob = new Blob([asArray], { type: 'image/png' });
		return blob;
	}
}
