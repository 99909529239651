export const ChartData = {
	spDonutDashboard: {
		labels: [],
		series: []
	},
	whatCanIdoDonut: {
		labels: [],
		series: []
	},
	lineArea2: {
		labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		series: [
			[]
		]
	},
	lineArea1: {
		labels: [],
		series: [
			[]
		]
	}
}