import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService, CookieModule } from '@gorniv/ngx-universal';
import { ToastrModule } from "ngx-toastr";
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService, AuthGuard, UnAuthGuard } from "./shared/auth";
import { InternetInterceptor, RequestInterceptor, ResponseInterceptor } from './interceptors';
import { CookieStorage, LocalStorage } from './storage';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { BreadcrumbModule } from "angular-crumbs";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
	declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
	imports: [
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		NgbModule,
		NgxSpinnerModule,
		BreadcrumbModule,
		DeviceDetectorModule.forRoot(),
		CookieModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		PerfectScrollbarModule,
		ToastrModule.forRoot({
			preventDuplicates: true
		})
	],
	providers: [
		AuthService,
		AuthGuard,
		UnAuthGuard,
		CookieService,
		CookieStorage,
		LocalStorage,
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
		{ provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
		{provide: LOCALE_ID, useValue: 'de' },
		WINDOW_PROVIDERS
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
