<div class="datable-header">
    <div class="card m-0 br-0">
        <div class="card-content">
            <div class="card-body remove-space">
                <div class="row tbh-row">
                    <div class="col-auto title-col" *ngIf="config?.title">

                        <h5 class="m-0" *ngIf="(config?.title == 'existing_service_persons');else other" >
                            {{totalcount}} {{config?.title | lowercase | translate}}
                        </h5>
                        <ng-template #other>
                            <h5 class="m-0" *ngIf="(currentUrl == '/existing-business-owners' && config?.title == 'existing_business_owners');else other1">
                                {{totalcount}} {{config?.title | lowercase | translate}}
                            </h5>
                        </ng-template>

                        <ng-template #other1>
                            <h5 class="m-0" *ngIf="(currentUrl == '/requested-business-owners' && config?.title == 'existing_business_owners');else other2">
                                {{totalcount}} {{'requested_business_units' | lowercase | translate}}
                            </h5>
                        </ng-template>

                        
                        <ng-template #other2>
                            <h5 class="m-0" *ngIf="(currentUrl == '/rejected-business-owners' && config?.title == 'existing_business_owners');else last">
                                {{totalcount}} {{'rejected_business_units' | lowercase | translate}}
                            </h5>
                        </ng-template>

                        <ng-template #last>
                            <h5 class="m-0" *ngIf="config?.title">
                                {{config?.title | lowercase | translate}}
                            </h5>
                        </ng-template>
                        

                    </div>


                    <div class="col-auto-dates col-auto" *ngIf="config?.filter.download2">
                        <span *ngIf="config?.filter.download2" class="mr-1 w-100" style="position: relative;">
                            <button class="btn btn-liked-you mr-sm-2 mb-1"
                             (click)="downloadAllQues()"
                             [disabled]="downloadButton">{{ 'downloadAllxls' | translate }}</button>
                         </span>
                         <span *ngIf="config?.filter.download2" class="mr-1 w-100 span-dates" style="position: relative;">
                            <input type="date" #startDate (input)="datePicker(startDate, '')" placeholder="dd.mm.yyyy">
                            <span class="ml-2 mr-2">to</span>
                            <input type="date" #endDate (input)="datePicker(startDate, endDate)"[min]="startDateValue">
                         </span>
                    </div>
                    <div class="show_wrapper">
                    <div class="col-auto ml-lg-auto">
 
                        {{'show' | translate }}
                        <select #limit class="form-control table-custom-header w-auto d-inline-block" (change)="onLimitChange($event)">
							<option *ngFor="let i of entries" [selected]="i === 10" [value]="i">{{i}}</option>
						</select> {{'entries' | translate }}
                    </div>
                    <div class="col-auto">
                        <div class=" row1 d-flex justify-content-end">
                            <div class="position-relative d-flex col-auto cc1" *ngIf="config?.filter.typeSearch">
                                <span class="mr-1 w-100" style="position: relative;">
										<input #search type="text" class="form-control table-custom-header mr-1 pr-4 search-input"
											placeholder="{{'search' | translate}}" id="searchid">
										<div class="form-control-position">
											<em class="ft-search"></em>
										</div>
									</span>
                            </div>                           
                            <div class="col-auto maxWidth-300" *ngIf="columns && columns.length > 1">
                                <select class="form-control table-custom-header d-inline-block" (change)="onFilterByChange($event)">
									<option value="">{{'filter_by'| translate}}</option>
									<option *ngFor="let column of columns" [value]="column.prop" [ngClass]="{ 'display-none': !column.prop}">{{column.name}}</option>
								</select>
                            </div>
                            <div class="position-relative d-flex col-auto download-btn" *ngIf="config?.filter.download">
                                <span class="mr-1 w-100" style="position: relative;">
										<div class="float-right">
											<button class="btn btn-liked-you mr-sm-2 mb-1"
												(click)="getCSV()">{{'download_offer_historyy' | translate}}
											</button>
										</div>
									</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>