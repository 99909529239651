
function getCellClass({ row, column, value }): any {
     return  row.active === true ? 'd-flex align-items-center break-all highlight' : 'd-flex align-items-center break-all'
    //  return  row.active === true ? 'd-flex align-items-center break-all highlight justify-content-center' : 'd-flex align-items-center break-all justify-content-center'
  }
export function columns(key: string) {
    const lang = localStorage.getItem('locale');
    let activeName=''
    let inactiveName=''
    if(lang == "en"){
        activeName='Active Vouchers'
        inactiveName='Inactive Vouchers'
    }else{
        activeName='Aktive Gutscheine'
        inactiveName='Inaktive Gutscheine'
    }

    if (key === 'business-levels') {
        const businessLevelCloumn = [
            {
                prop: 'name',
                searchProp: 'name',
				name: 'business_level_name',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
        ];
        return businessLevelCloumn;
    }else if(key === 'rating-label'){
        const labelCategoryColumn = [
            // {
            //     prop: 'label',
            //     name: 'RatingLabel',
            //     sortable: false,
            //     searchProp: 'name',
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto',
            // }
                ];
        return labelCategoryColumn;
    }else if (key === 'rating-question') {
        const questionCategoryColumn = [
            {
                prop: 'question',
                name: 'questions',
                sortable: false,
                searchProp: 'name',
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            }
            ,{
                prop: 'category',
                name: 'category',
                sortable: false,
                searchProp: 'name',
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
        ];
        return questionCategoryColumn;
    } else if (key === 'business-owner') {
        const businessOwnerCloumn = [
            {
                prop: 'businessName',
                searchProp: 'businessName',
                name: 'business_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center',
                width: 190,
            },
            {
                prop: 'lastName',
                searchProp: 'lastName',
                name: 'last_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'firstName',
                searchProp: 'firstName',
                name: 'first_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'email',
                searchProp: 'email',
                name: 'email_add',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center',
                width: 250,
            },
            {
                prop: 'contactNumber',
                searchProp: 'contactNumber',
                name: 'contact_numberr',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'businessLevel',
                name: 'business_level',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            // {
            //     prop: 'businessCategory',
            //     searchProp: 'businessCategory',
            //     name: 'Business Category',
            //     sortable: true,
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto' badge badge-pill bg-light-success,
            // }
        ];
        return businessOwnerCloumn;
    } else if (key === 'industry') {
        const industryColumn = [
            {
                prop: 'title',
                searchProp: 'title',
                name: 'title',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all'
                // width: 'auto',
            }
        ];
        return industryColumn;
    } else if (key === 'service-category') {
        const serviceCategoryColumn = [
            {
                prop: 'name',
                searchProp: 'name',
                name: 'category',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all'
                // width: 'auto',
            }
        ];
        return serviceCategoryColumn;
    } else if (key === 'service-provider') { 
        const serviceProviderColumn = [
            {
                prop: 'lastName',
                searchProp: 'lastName',
                name: 'last_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'firstName',
                searchProp: 'firstName',
                name: 'first_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            // {
            //     prop: 'businessOwner',
            //     name: 'business_owner',
            //     sortable: false,
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto',
            // },
            {
                prop: 'email',
                searchProp: 'email',
                name: 'email_add',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center',
                width: 250
            },
            {
                prop: 'category',
                name: 'category',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'status',
                name: 'status',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            }
        ];
        return serviceProviderColumn;
    } else if (key === 'rating-question') {
        const ratingQuestionColumn = [
            {
                prop: 'question',
                searchProp: 'question',
                name: 'question',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all'
                // width: 'auto',
            }
        ];
        return ratingQuestionColumn;
    } else if (key === 'team-service-provider') { 
        const teamServiceProviderColumn = [
            {
                prop: 'lastName',
                searchProp: 'lastName',
                name: 'last_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'firstName',
                searchProp: 'firstName',
                name: 'first_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'email',
                searchProp: 'email',
                name: 'email_add',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center',
                width: 250
            },
            {
                prop: 'businessOwner',
                name: 'business_owner_team',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'contactNumber',
                searchProp: 'contactNumber',
                name: 'contact_numberr',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'category',
                name: 'category',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            // {
            //     prop: 'employeeId',
            //     searchProp: 'employeeId',
            //     name: 'Employee ID',
            //     sortable: true,
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto',
            // }
        ];
        return teamServiceProviderColumn;
    } else if (key === 'team') { 
        const teamColumn = [
            
            {
                prop: 'name',
                searchProp: 'name',
                name: 'team_namee',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'latestComment',
                name: 'latest_rating',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center',
                width:180,
            },
            {
                prop: 'ServicePersonCategory.name',
                name: 'category',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center',
                 width:120,
            },
            // {
            //     prop: 'status',
            //     name: 'status',
            //     sortable: true,
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto',
            // }
        ];
        return teamColumn;
    } else if (key === 'designation-management') {
        const designationCloumn = [
            {
                prop: 'name',
                searchProp: 'name',
				name: 'Designation',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'level',
				name: 'Level',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
        ];
        return designationCloumn;
    } else if (key === 'offer-management') {
        const offerCloumn = [
            {
                prop: 'name',
                searchProp: 'name',
				name: 'reward_name',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass : getCellClass
                // width:'auto'
            },
            
            {
                prop: 'type',
                searchProp: 'type',
				name: 'Reward Type',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass : getCellClass
            },
            {
                prop: 'category',
                name: 'category',
                sortable: true,
                searchProp: 'name',
                draggable: true,
                canAutoResize: true,
                cellClass : getCellClass
                // width: 'auto',
            },
            {
                prop: 'couponCode',
                searchProp: 'couponCode',
				name: 'coupon_code',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass :  getCellClass
            },
            {
                prop: 'minRating',
                searchProp: 'minRating',
				name: 'minimum_rating',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass : getCellClass
            },
            {
                prop: 'redeemDaysGap',
                searchProp: 'redeemDaysGap',
				name: 'time_gap_dayss',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass : getCellClass
            },
            // {
            //     prop: 'category',
            //     name: 'category',
            //     sortable: false,
            //     searchProp: 'name',
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass : getCellClass
            //     // width: 'auto',
            // },
           

            {
                prop: 'active',
                searchProp: 'activeVoucher',
				name: activeName,
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass : getCellClass
            }, 
            {
                prop: 'inactive',
                searchProp: 'inactiveVoucher',
				name: inactiveName,
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass : getCellClass
                
            },
            {
                prop: 'redeemedCount',
                name: 'redemption',
                sortable: false,
                searchProp: 'New Column',
                draggable: true,
                canAutoResize: true,
                cellClass : getCellClass
                    // width: 'auto',
            },

        ];
        return offerCloumn;
    } else if (key === 'redeem-history') {
        const offerCloumn = [
            {
                prop: 'name',
                searchProp: 'name',
				name: 'user_name',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'email',
                searchProp: 'email',
				name: 'user_email',
				sortable: false,
				draggable: true,
				canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all',
                width: 250
            },
            {
                prop: 'redeemedAt',
                searchProp: 'redeemedAt',
				name: 'redeemed_date',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            }
        ];
        return offerCloumn;
    } else if (key === 'custom-offer-management') {
        const offerCloumn = [
            {
                prop: 'name',
                searchProp: 'name',
				name: 'reward_name',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            // {
            //     prop: 'type',
            //     searchProp: 'type',
			// 	name: 'Reward Type',
			// 	sortable: true,
			// 	draggable: true,
			// 	canAutoResize: true,
			// 	cellClass: 'd-flex align-items-center break-all'
            // },
            {
                prop: 'couponCode',
                searchProp: 'couponCode',
				name: 'coupon_code',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            },
            {
                prop: 'address',
                searchProp: 'address',
				name: 'Address',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center'
            },
            {
                prop: 'radius',
                searchProp: 'radius',
				name: 'Radius',
				sortable: true,
				draggable: true,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            }
        ];
        return offerCloumn;
    } else if (key === 'rating-details') {
        const ratingDetailsCloumn = [
            {
                prop: 'name',
                searchProp: 'name',
				name: 'Rater',
				sortable: false,
				draggable: false,
                width : 150,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center break-all'
            }
        ];
        return ratingDetailsCloumn;
    } else if (key === 'sp-dashboard') { 
        const spDashboardColumn = [
            {
                prop: 'lastName',
                searchProp: 'lastName',
                name: 'last_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'firstName',
                searchProp: 'firstName',
                name: 'first_name',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
           
            {
                prop: 'latestComment',
                name: 'latest_rating',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center',
                 width: 200,
            },

            // {
            //     prop: 'userRatingDevice',
            //     name: 'rating_device',
            //     sortable: false,
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center',
            //     width: 200,
            // },
            {
                prop: 'position',
                name: 'category',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            
            // {
            //     prop: 'lastRatingComment',
            //     name: 'Individual Comments',
            //     sortable: false,
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'd-flex align-items-center'
            //     // width: 'auto',
            // }
        ];
        return spDashboardColumn;
    } else if (key === 'sp-team') { 
        const spTeamColumn = [
            {
                prop: 'name',
                searchProp: 'name',
                name: 'Name',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'position',
                name: 'category',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'chain',
                name: 'Hotel Chain',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'lastRatedAt',
                name: 'Date',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            },
            {
                prop: 'lastRatingComment',
                name: 'Individual Comments',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center'
                // width: 'auto',
            }
        ];
        return spTeamColumn;
    } else if (key === 'faq') {
        const faqColumn = [
            {
                prop: 'name',
                searchProp: 'category',
                name: 'category',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellClass: 'd-flex align-items-center break-all'
                // width: 'auto',
            }
        ];
        return faqColumn;
    }


}
