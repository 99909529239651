import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { APP_ROUTES, ApiRoutes } from 'app/shared/routes';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { IBusinessLevel, IIndustry, IBusinessOwners, IRating, IDTConfig, ISPTeam } from 'app/interfaces';
import { triangleBall, columns } from 'app/shared/utility';
import { AuthService } from 'app/shared/auth';
import { DataService, SwalService, ToastService } from 'app/shared/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertType } from 'sweetalert2';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { ratingConfig } from '../rating.config';
import { DatePipe } from '@angular/common';
import { teamConfig } from '../team.config';
import { encode, decode } from 'js-base64';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-service-provider-detail',
  templateUrl: './team.ratings.component.html',
  styleUrls: ['./team.ratings.component.scss']
})
export class teamRatingComponent implements OnInit, OnDestroy {
	id;
	user: any = {};
	@ViewChild('actionT', { static: false }) public actionT: TemplateRef<any>;
	@ViewChild('sNo', { static: false }) public sNo: TemplateRef<any>;
	@ViewChild('rating', { static: false }) public rating: TemplateRef<any>;
	@ViewChild('createdDate', { static: false }) public createdDate: TemplateRef<any>;
	@ViewChild('truncatedComment', { static: false }) public truncatedComment: TemplateRef<any>;
	@ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
	public config: IDTConfig = ratingConfig;
	public rows: IRating[] = [];
	public temp: IRating[] = [];
	public limit: number = 10;
	public languageMessage: string = "";
	public columns: TableColumn[] = [];
	public selectedItems: number[] = [];
	public DisabledFooter :Boolean = false;
	selected = [];
	public pagePayload: any = {
		page_no: 1,
		page_length: 5,
		search: ''
	}
	@ViewChild('teamActionT', { static: false }) public teamActionT: TemplateRef<any>;
	@ViewChild('teamSNo', { static: false }) public teamSNo: TemplateRef<any>;
	@ViewChild('teamRating', { static: false }) public teamRating: TemplateRef<any>;
	@ViewChild(DatatableComponent, { static: false }) teamTable: DatatableComponent;
	public teamConfig: IDTConfig = teamConfig;
	public teamRows: ISPTeam[] = [];
	public teamTemp: ISPTeam[] = [];
	public teamLimit: number = 10;
	public footerHeight:number = 50
	public teamColumns: TableColumn[] = [];
	public teamColumnsToSearch: TableColumn[] = [];
	public teamSelectedItems: number[] = [];
	teamSelected = [];
	public teamPagePayload: any = {
		page_no: 1,
		page_length: 10,
		search: ''
	}
	APP_ROUTES = APP_ROUTES;
	team;
	ratingDetailModal = null;
	currentRating;
	chainArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
	constructor(public translate: TranslateService, private toastr: ToastService, private ds: DataService, private swal: SwalService, private router: Router, private spinner: NgxSpinnerService, private route: ActivatedRoute, public auth: AuthService) { 
		this.id = this.route.snapshot.paramMap.get('id');
		setTimeout(() => {
			this.columns = columns(this.config.type);
			
			const action:any = {
				prop: '',
				name: 'action',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellTemplate: this.actionT,
				width: 150,
				cellClass: 'd-flex align-items-center'
			}
			const serialNo: any = {
				prop: 'sNo',
				name: 's_no.',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellTemplate: this.sNo,
				cellClass: 'd-flex align-items-center',
			}
			const rating: any = {
				prop: 'rating',
				name: 'ratings',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellTemplate: this.rating,
				cellClass: 'd-flex align-items-center',
			}
			const ratingdevice: any = {
				prop: 'userRatingDevice',
				name: 'type_of_rating',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				// cellTemplate: this.userRatingDevice,
				cellClass: 'd-flex align-items-center',
			}
			
			const createdDate: any = {
				prop: 'createdDate',
				name: 'date',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellTemplate: this.createdDate,
				cellClass: 'd-flex align-items-center',
			}
			const truncatedComment: any = {
				prop: 'truncatedComment',
				name: 'comment',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellTemplate: this.truncatedComment,
				cellClass: 'd-flex align-items-center',
			}
			this.columns.splice(1, 0, rating);
			this.columns.splice(2, 0, ratingdevice);
			this.columns.splice(3, 0, createdDate);
			this.columns.splice(4, 0, truncatedComment);
			this.columns.splice(5, 0, action);
			// this.columns.splice(5, 0, serialNo);

			// For Team List
			this.teamColumns = columns(this.teamConfig.type);
			this.teamColumnsToSearch = [...this.teamColumns];
			this.teamColumns.push(
				{
					prop: '',
					name: 'action',
					sortable: false,
					draggable: true,
					canAutoResize: true,
					cellTemplate: this.teamActionT,
					width: 250,
					cellClass: 'd-flex align-items-center'
				},
			);
			// const teamSerialNo: any = {
			// 	prop: 'teamSNo',
			// 	name: 'S No.',
			// 	sortable: false,
			// 	draggable: true,
			// 	canAutoResize: true,
			// 	cellTemplate: this.teamSNo,
			// 	cellClass: 'd-flex align-items-center',
			// }
			const teamRating: any = {
				prop: 'rating',
				name: 'average_rating',
				sortable: false,
				draggable: true,
				canAutoResize: true,
				cellTemplate: this.teamRating,
				cellClass: 'd-flex align-items-center',
			}
			const totalRating: any = {
				prop: 'totalRating',
				name: 'number_of_ratings',
				sortable: false,
				draggable: true,
				canAutoResize: true,				
				cellClass: 'd-flex align-items-center',
			}
			this.teamColumns.splice(3, 0, teamRating);
			this.teamColumns.splice(4, 0, totalRating);
			//console.log(this.teamColumns)
			this.teamColumns.splice(5,2)
			// this.teamColumns.splice(8, 0, teamSerialNo);
			//console.log(this.teamColumns)
			this.teamColumns.splice(2,1);	
			for(const col of this.columns) {
				col.name = this.translate.instant(col.name);
			}
			for(const col of this.teamColumns) {
				col.name = this.translate.instant(col.name);
			}		
		});
		this.getUserDetails();
		this.getRatingList();
	}

	ngOnInit(): void {
		// When the user clicks anywhere outside of the modal, close it
		this.ratingDetailModal = document.getElementById("teamRatingDetailModal");
		this.ratingDetailModal.style.display = "none";
		// window.onclick = function(event) {
		// 	if (event.target == this.ratingDetailModal) {
		// 		this.ratingDetailModal.style.display = "none";
		// 	}
		// }
	}

	ngOnDestroy(): void {
		this.ratingDetailModal.style.display = 'none';
	}

	// Get User Details
	private getUserDetails(loaderFlag: boolean = true): void {
		const url: string = ApiRoutes.getSTeam + APP_ROUTES.slash + this.id;
		this.ds.spinnerTxt = '';
		this.spinner.show(undefined, triangleBall)
		
		this.ds.get(url).subscribe((res) => {
			
			this.ds.spinnerTxt = '';
			this.spinner.hide();
			if(res.success) {
				// res.data.chain = (res.data.User && res.data.User.chainId && this.chainArr[(res.data.User.chainId) - 1]) ? 'Chain ' + (this.chainArr[(res.data.User.chainId) - 1]) : '';
				res.data.businessName = res.data.User && res.data.User.businessName;
				res.data.category = res.data.ServicePersonCategory && res.data.ServicePersonCategory.name;
				res.data.lastRatingDate = res.data.lastRatedAt && new DatePipe(
					"en-GB"
				  ).transform(
					res.data.lastRatedAt,
					"dd.MM.y"
				  );
				// res.data.lastRatingTime = res.data.lastRatedAt && new DatePipe(
				// "en-GB"
				// ).transform(
				// res.data.lastRatedAt,
				// "HH:mm"
				// );
				res.data.lastRatingTime = res.data.lastRatedAt ? moment(res.data.lastRatedAt).format("HH:mm") : '';
				
				this.user = res.data;
				const el: any = document.getElementById('edit-sp');
				el.click();
				// this.getTeams();
			}
		}, error => {
			this.ds.spinnerTxt = '';
			this.spinner.hide();
		});
	}

	// Truncate string
	truncate(str, length) {
		const ending = '...';
		if (str.length > length) {
		  return str.substring(0, length - ending.length) + ending;
		} else {
		  return str;
		}
	};

	// Get Rating List
	private getRatingList(): void {
		this.spinner.show(undefined, triangleBall);
		const url: string = ApiRoutes.teamRatings;
		this.pagePayload.id = this.id;
		this.ds.post(url, this.pagePayload).subscribe((res) => {
			this.hideLoader(true);
			this.limit = res.data.ratingCount;
			if (this.limit == 0) {
				this.languageMessage = localStorage.getItem("locale") && this.translate.instant('no_data_to_display')
			}
			if(this.limit < 11){
				this.DisabledFooter = true
							}
			res.data.ratings.map(i => {
				i.name = i.GuestUser && i.GuestUser.fullName;
				i.createdDate = i.createdAt && new DatePipe(
					"en-GB"
				  ).transform(
					i.createdAt,
					"dd.MM.y"
				  );
				  i.createdTime = moment(i.createdAt).format("HH:mm");

				//i.truncatedComment = i.comment && this.truncate(i.comment, 20)
				i.truncatedComment = i.comment && this.truncate(decode(i.comment), 20)
				i.createdAt = i.createdAt && new DatePipe(
					"en-GB"
				  ).transform(
					i.createdAt,
					"dd.MM.y"
				  );
				return i;
			});
			for(let i = 0; i< this.columns.length; i++){
				if(this.columns[i].sortable){
				document.getElementsByClassName("sort-btn")[i].classList.add("datatable-icon-down");
					}
			}
			this.temp = res.data.ratings;
			this.rows = [...this.temp];
			
			const el: any = document.getElementById('user-management');
			el.click();
		}, err => {
			this.hideLoader(true);
		});
	}

	// Get Teams
	// private getTeams(): void {
	// 	const url: string = ApiRoutes.teamRatings;
	// 	this.teamPagePayload['id'] = this.id;
	// 	console.log(url);
		
	// 	this.ds.post(url, this.teamPagePayload).subscribe((res) => {
	// 		console.log('dataaaa ===>>> ',res.data);
			
	// 		this.teamLimit = res.data.ratingCount;
	// 		res.data.ratings.map(i => {
	// 			i.chain = (i.User && i.User.chainId && this.chainArr[(i.User.chainId) - 1]) ? 'Chain ' + (this.chainArr[(i.User.chainId) - 1]) : '';
	// 			i.category = i.ServicePersonCategory && i.ServicePersonCategory.name;
	// 			i.lastRatingDate = i.lastRatedAt && new DatePipe(
	// 				"en-GB"
	// 			  ).transform(
	// 				i.lastRatedAt,
	// 				"d.MM.y"
	// 			  );
	// 			// i.lastRatingTime = i.lastRatedAt && new DatePipe(
	// 			// "en-GB"
	// 			// ).transform(
	// 			// i.lastRatedAt,
	// 			// "h:mm a"
	// 			// );
	// 			i.lastRatingTime = moment(i.lastRatedAt).format("HH:mm");
	// 		});
	// 		this.teamTemp = res.data.ratings;
	// 		this.teamRows = [...this.teamTemp];
	// 		const el: any = document.getElementById('team-management');
	// 		el.click();
	// 	}, err => {;
	// 	});
	// }

	// Get Team Detail
/* 	private getTeamDetail(teamId): void {
		const url: string = ApiRoutes.getSTeam + APP_ROUTES.slash + teamId;
		this.ds.spinnerTxt = '';
		this.spinner.show(undefined, triangleBall)
		this.ds.get(url).subscribe((res) => {
			this.ds.spinnerTxt = '';
			this.spinner.hide();
			if(res.success) {
				res.data.lastRatingDate = res.data.lastRatedAt && new DatePipe(
					"en-GB"
				  ).transform(
					res.data.lastRatedAt,
					"d-MMMM-y"
				  );
				res.data.lastRatingTime = res.data.lastRatedAt && new DatePipe(
				"en-GB"
				).transform(
				res.data.lastRatedAt,
				"h:mm a"
				);
				this.team = res.data;
				this.getServiceProviders();
			}
		}, error => {
			this.ds.spinnerTxt = '';
			this.spinner.hide();
		});
	} */

	// get list of service providers
	/* private getServiceProviders(): void {
		const url: string = ApiRoutes.getSTeamMembers;
		this.spPagePayload.serviceProviderIds = this.team.serviceProviderIds;
		this.ds.post(url, this.spPagePayload).subscribe((res) => {
			this.spLimit = res.data.serviceProvidersCount;
			res.data.serviceProviders.map(i => {
				i.businessOwner = i.User && i.User.firstName + ' ' + i.User.lastName;
				i.category = i.ServicePersonCategory && i.ServicePersonCategory.name;
			});
			this.spTemp = res.data.serviceProviders;
			this.spRows = [...this.spTemp];
			const el: any = document.getElementById('sp-management');
			el.click();
		}, err => {;
		});
	} */

	// Close Modal
	closeModal() {
		this.ratingDetailModal.style.display = 'none';
	}

	private hideLoader(loaderFlag: boolean): void {
		if (loaderFlag) {
			this.spinner.hide();
		}
	}

	printSomething() {
		console.log('printed here')
	}

	viewDetail(row): void {
		this.currentRating = JSON.parse(JSON.stringify(row));
		this.currentRating.comment = decode(this.currentRating.comment);
		if(this.currentRating.questionFeedbacks){
			let questionFeedbacks=this.currentRating.questionFeedbacks.map((element)=>{
				element.answer = element.answer ? decode(element.answer) : '';
				return element;
			})
			this.currentRating.questionFeedbacks = questionFeedbacks;
		}
		
		//this.currentRating.createdAt = moment(this.currentRating.createdAt).format("d MMMM YYYY HH:mm");
		// console.log(this.ratingDetailModal.style.display);
		
		this.ratingDetailModal.style.display = 'block';
	}

	// Get rating table row index
	public getRowIndex(row: any): number {
		return this.table.bodyComponent.getRowIndex(row); // row being data object passed into the template
	}

	// Get members table row index
	public getTeamRowIndex(row: any): number {
		return this.teamTable.bodyComponent.getRowIndex(row); // row being data object passed into the template
	}

	// get row identity
	getId(row) {
		return row.id;
	}

	// getTeamId(row) {
	// 	return row.id;
	// }

	// data table sort
	onSort(e): void { }

	// rating data table page change
	onPageChange(e): void {
		this.pagePayload.page_no = e.offset + 1;
		this.getRatingList();
	}

	// members data table page change
	onTeamPageChange(e): void {
		this.pagePayload.page_no = e.offset + 1;
		// this.getTeams();
	}

	// data table row select
	public onRowSelect(e): void {
		this.selectedItems = this.selected.map(i => {
			return i.id
		});
	}

	// on members table header action change
	// public onSPChange(obj: any): void {
	// 	switch (obj.eventType) {
	// 		case 'textSearch':
	// 			this.spPagePayload.search = obj.val;
	// 			// this.getOffers(false);

	// 			this.spTable.offset = 0;
	// 			this.onSPPageChange({ offset: 0 });
	// 			break;
	// 		case 'pageLimitChange':
	// 			this.spPagePayload.page_length = obj.val;
	// 			this.spTable.offset = 0;
	// 			this.onSPPageChange({ offset: 0 });
	// 			break;
	// 		case 'filterByChange':
	// 			this.spPagePayload.attribute = obj.val;
	// 			this.spTable.offset = 0;
	// 			this.onSPPageChange({ offset: 0 });
	// 			break;
	// 	}
	// }
}
