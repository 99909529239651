import { IDTConfig } from '../../../interfaces';
export const ratingConfig: IDTConfig = {
    type: 'rating-details',
    title: 'Rating Details',  
    filter: { 
        typeSearch: true, 
        date: false,
        dateRange: true,
        dd: false
    }, 
    isAddBtn: true
}