import { IDTConfig } from '../../../interfaces';

export const teamServiceProviderConfig: IDTConfig = {
    type: 'team-service-provider',
    title: 'Selectservicepersons',  
    filter: { 
        typeSearch: true, 
        date: false,
        dateRange: true,
        dd: false
    }, 
    isAddBtn: true
}

export const teamConfig: IDTConfig = {
    type: 'team', 
    title: '', 
    filter: { 
        typeSearch: true, 
        date: false,
        dateRange: true,
        dd: false
    }, 
    isAddBtn: true
}