import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ToastService } from '../shared/services';
import { AuthService } from '../shared/auth';
import { Router } from '@angular/router';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private toastr: ToastService, private auth: AuthService, private route: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse) {
                    // if (request.method !== 'GET') {
                    if ((this.route.url === '/service-provider-management' && ev.url.substr(ev.url.length - 6) == 'update')
                        || ((ev.url.substr(ev.url.length - 27) == 'serviceProvider/verifyToken') && ev.body.user == null)) {
                        //This is done only to hide success msg at one position
                    }
                    else if (ev.body.msg) {
                        this.notifier(ev.body.msg, 'success');
                    }
                    // }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                // If Notification (toast) service is present we can show current error notification
                this.errorHandler(error);
                return throwError(error);
            })
        );
    }

    private errorHandler(error: HttpErrorResponse, type: string = 'error') {
        switch (error.status) {
            case 401: {
                this.notifier('Access token expired', type);
                this.auth.logout();
                break;
            }
            case 400: {
                this.notifier(error.error.msg, type);
                break;
            }
            case 404: {
                this.notifier(error.error.msg, type);
                break;
            }
            case 500: {
                this.notifier(error.error.msg, type);
                break;
            }
            case 0: {
                this.notifier('Seems there is some problem with the server. Try later!', type);
                break;
            }
        }
    }

    private notifier(msg: string, type: string = 'success') {
        if (type === 'error') {
            this.toastr.error(msg);
            return;
        }
        if (msg === "Your account has been created. We have sent you a verification email" || "Dein Account wurde erstellt. Wir haben Dir eine Verifizierungs-E-Mail gesendet.") {
            this.toastr.success2(msg);
        }
        this.toastr.success(msg);
    }
}