<div class="form-group hidden"> 
	<div class="input-group">
		<input name="datepicker" class="form-control d-none" ngbDatepicker #rangepicker="ngbDatepicker" [autoClose]="'outside'"
			(dateSelect)="onDateSelection($event, rangepicker)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
			[startDate]="fromDate!" [readonly]="true" [maxDate]="maxDate">
		<ng-template #t let-date let-focused="focused">
			<span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
				[class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
				(mouseleave)="hoveredDate = null">
				{{ date.day }}
			</span>
		</ng-template>
	</div>
</div>
<div class="input-group mb-0 date-range">
	<!-- [value]="formatter.format(fromDate) + ' - ' + formatter.format(toDate)" -->
	<input #dpFromDate class="form-control" placeholder="" name="dpFromDate" [readonly]="true" [(ngModel)]="selectedRange"
	 (click)="rangepicker.toggle()"
	(input)="fromDate = validateInput(fromDate, dpFromDate.value)">
	<div class="input-group-append">
		<button class="btn btn-outline-secondary calendar px-1" (click)="rangepicker.toggle()">
			<em class="fa fa-calendar"></em>
		</button>
	</div>
	<!-- <input #dpToDate class="form-control ml-1" placeholder="To" name="dpToDate" [value]="formatter.format(toDate)"
		(input)="toDate = validateInput(toDate, dpToDate.value)" [readonly]="true">
	<div class="input-group-append">
		<button class="btn btn-outline-secondary calendar px-1" (click)="rangepicker.toggle()">
			<em class="fa fa-calendar"></em>
		</button>
	</div> -->
</div>