import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../auth';

@Pipe({
  name: 'dashboard'
})
export class DashboardPipe implements PipeTransform {
  constructor(private authservice : AuthService){
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    console.log('servi  '+this.authservice.role)
    if(this.authservice.role == 'admin'){
      return 'Business_Person_Overview'
    }else{
      return 'ratings'
    }
  }

}
