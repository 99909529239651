<div class="row">
    <div class="col-12">
        <div class="content-header">{{'detail_view' | translate}} {{user?.firstName}} {{user?.lastName}}</div>
    </div>
</div>
<!-- Business Owner Mangement content starts -->
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-content">
                <div class="card-body">
                    <ul class="tabs" id="edit-sp">
                        <li class="tab">
                            <input type="radio" name="tabs" checked="checked" id="tab1" />
                            <label for="tab1" style="text-transform:capitalize; background-color: #f57100 ;position: absolute; left: 0">{{'team_ratings' | translate}}</label>
                            <div id="tab-content1" class="content">
                                <app-rating *ngIf="user && user.avgRating" [ratingText]="true" [totalRating]="user.totalCount" [rating]="user.avgRating"></app-rating>
                                <div class="form-group row">
                                    <label for="lastName" class="col-sm-3 col-form-label">{{'team_name' | translate}}</label>
                                    <div class="controls col-sm-6">
                                        <span>{{user?.name}}</span>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label for="email" class="col-sm-3 col-form-label">{{'business_name' | translate}}</label>
                                    <div class="controls col-sm-6">
                                        <span>{{user?.businessName}}</span>
                                    </div>
                                </div> -->
                                <div class="form-group row" *ngIf="user?.ServicePersonCategory">
                                    <label for="servicePersonCategoryId" class="col-sm-3 col-form-label">{{'category' | translate}}</label>
                                    <div class="controls col-sm-6">
                                        <span>{{user?.category}}</span>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                    <label for="email" class="col-sm-3 col-form-label">Hotel Chain</label>
                    <div class="controls col-sm-6">
                      <span>{{user?.chain}}</span>
                    </div>
                  </div> -->
                                <div class="form-group row">
                                    <label for="contactNumber" class="col-sm-3 col-form-label">{{'added_on' | translate}}</label>
                                    <div class="controls col-sm-6">
                                        <span>{{user.lastRatingDate ? user.lastRatingDate : ''}}</span>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label for="employeeId" class="col-sm-3 col-form-label">{{'time' | translate}}</label>
                                    <div class="controls col-sm-6">
                                        <span>{{user.lastRatingTime ? user.lastRatingTime : ""}}</span>
                                    </div>
                                </div> -->

                                <!-- Rating Details -->
                                <section id="user-management">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card m-0 br-0">
                                                <div class="mt-2">
                                                    <h5 (click)="printSomething()">{{'rating_detail' | translate}}</h5>
                                                    <div class="scrollable-inner">
                                                        <ngx-datatable id="data-table" class="expandable" [ngClass]="DisabledFooter == true ? 'hide-footer' : 'active' " #table class='bootstrap core-bootstrap header-height-50' [columns]="columns" [rows]='rows' [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                                            [selectionType]="'checkbox'" [columnMode]="'force'" (select)='onRowSelect($event)' [loadingIndicator]='' [limit]="pagePayload.page_length" [externalPaging]="true" [externalSorting]="true" [count]="limit"
                                                            (sort)="onSort($event)" (page)="onPageChange($event)" [selected]="selected" [rowIdentity]="getId" [messages]="{emptyMessage: languageMessage}" [scrollbarH]="true">
                                                        </ngx-datatable>
                                                        <!-- <ng-template #sNo let-row="row" let-value="value" let-i="index">
                                <span>{{ getRowIndex(row) + 1 }}</span>
                              </ng-template> -->
                                                        <ng-template #rating let-row="row" let-value="value" let-i="index">
                                                            <span>
                                  <app-rating *ngIf="row && row.rating" [ratingText]="false" [rating]="row.rating"></app-rating>
                                </span>
                                                        </ng-template>
                                                        <ng-template #actionT let-row="row" let-value="value" let-i="index">
                                                            <button class="btn btn-info btn-raised mb-0 mr-1" (click)="viewDetail(value)">Details</button>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="teamRatingDetailModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
        <span class="close" (click)="closeModal()">&times;</span>
        <h3 class="modal-title text-left  mb-1 mt-2"><b>{{'rating_detail' | translate }} - {{user?.name | translate}}</b></h3>
        <div class="mt-2" *ngIf="currentRating">

            <div class="row mb-2">
                <div class="col-5 fw-500 color1">{{'rating_by' | translate}}</div>
                <div class="col-7 color2"><span>{{currentRating?.GuestUser?.fullName}}</span></div>
            </div>

            <div class="row mb-2">
                <div class="col-5 fw-500 color1">{{'date' | translate}}</div>
                <div class="col-7 color2"><span><i class="ft-calendar"></i> {{currentRating?.createdAt}}</span></div>
            </div>

            <div class="row mb-4">
                <div class="col-5 fw-500 color1">{{'time' | translate}}</div>
                <div class="col-7 color2"><span><i class="ft-clock"  style="padding-right:3px;"></i>{{currentRating?.createdTime}} Uhr</span></div>
            </div>

            <div class="row">
                <div class="col-5 fw-500 color1">{{'rating' | translate}}</div>
                <div class="col-7 color2 RatingDiv"><span><app-rating [rating]="currentRating?.rating" [ratingText]="false"></app-rating></span></div>
            </div>
            <div class="row mb-2" *ngIf="currentRating?.userRatingLabel && currentRating?.userRatingLabel?.length > 0">
                <div class="col-5 fw-500 color1">{{'labels' | translate}}</div>
                <div class="col-7 color2">
                    <span>{{currentRating?.userRatingLabel}}</span>
                    <!-- <span *ngIf="!currentRating?.userRatingLabel">No label selected by rater</span>                -->
                </div>
            </div>

            <div class="row mb-4" *ngIf="currentRating?.comment?.length > 0">
                
                <div class="col-5 fw-500 color1">{{'comments' | translate }}</div>
                <div class="col-7 color2">
                    <span>{{currentRating?.comment}}</span>
                    <!-- <span *ngIf="!currentRating?.comment">N/A</span> -->
                 </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="QuestionsBlock" *ngFor="let qf of currentRating?.questionFeedbacks; let i = index;">
                        <p class="fw-500 color1">
                            {{i + 1}}. {{qf?.question}}
                        </p>
                        <p class="color2">{{qf?.answer}}</p>
                    </div>
                </div>
            </div>



            <!-- <div>
                <app-rating [rating]="currentRating?.rating" [ratingText]="false"></app-rating>
                <h6>{{'comments' | translate }}</h6>
            </div> -->
            
            <!-- <div class="border-box">
                <span *ngIf="currentRating?.comment">{{currentRating?.comment}}</span>
                <span *ngIf="!currentRating?.comment">N/A</span>
            </div> -->

            <!-- <div class="border-box" *ngIf="currentRating?.questionFeedbacks?.length">
                <h6>{{'answers_questions' | translate }}</h6>
                <div *ngFor="let qf of currentRating?.questionFeedbacks; let i = index;">
                    <p>
                        <b>{{i + 1}}. {{qf?.question}}</b>
                    </p>
                    <p>{{qf?.answer}}</p>
                </div>
            </div> -->
            <!-- <div>
                <h6>{{'rating_by' | translate}}: {{currentRating?.GuestUser?.fullName}}</h6>
                <span><i class="ft-clock"></i> {{currentRating?.createdAt}} Uhr</span>
            </div> -->
        </div>
        <!-- <div class="d-flex flex-sm-row flex-column justify-content-center mt-3">
            <button class="btn btn-liked-you mr-sm-2 mb-1" (click)="closeModal()">{{'okay' | translate}}</button>
        </div> -->
    </div>

</div>