import { HttpHandler, HttpEvent, HttpRequest, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { AuthService } from '../shared/auth';
// import { locale } from "core-js";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = (this.auth.isAuthenticated() && localStorage.getItem('token')) ? 'Bearer ' + this.auth.token : '';
		request = request.clone({
			setHeaders: {
				Authorization: token,
				lang:localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de',
				deviceType: localStorage.getItem('deviceType') ? localStorage.getItem('deviceType'): 'iOS'

			}
		});
		return next.handle(request);
    }
}