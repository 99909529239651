<!--Rating Starts-->
<ng-template #t let-fill="fill">
  <span *ngIf="fill === 100" class="star full">&#9733;</span>
  <span *ngIf="fill === 0" class="star">&#9733;</span>
  <span *ngIf="fill < 100 && fill > 0" class="star">
    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
  </span>
</ng-template> 
 

<ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="maxRating || 5">&#9733;</ngb-rating>
<span *ngIf="ratingText" class="rating-val"><b>({{totalRating || 0}})</b></span>

<!--Rating Ends-->
