import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs/Rx'
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

import { ApiRoutes, APP_ROUTES } from '../routes';
import { LocalStorage } from '../../storage';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
	public environment = environment.apiBaseUrl;
	private authToken: string;
	public role: string;
	public actualAddedBy: number;
	public addedBy: any;
	public userId: any;
	public chainId :number;
	public createdAt;
	public businessLevel:any = {};
	private authState: BehaviorSubject<boolean>;
	private initialData: string[] = ['token'];

	constructor(private http: HttpClient, private router: Router, private ls: LocalStorage) {
		this.authState = new BehaviorSubject(false);
		this.authToken = this.ls.getItem('token');
		const user = this.authToken && (this.ls.getItem('likedyou-currentUser-' + this.authToken))
		
		if(user) {
			this.role = user.role;
			this.chainId = user.chainId;
			this.businessLevel = user.Businesslevel;
			this.userId = user.id;
			this.createdAt = user.createdAt;
			this.actualAddedBy = user.addedBy;
			// this.addedBy = (user.role == 'admin' || (user.role == 'businessOwner' && user.addedBy == 1)) ? user.id : user.addedBy;
	        this.addedBy = user.id;
	 	}
		// this.initialData.forEach((value) => {
		// 	this[value] = this.ls.getItem(value);
		// });
	}

	/** 
	  * Login action
	  * @param payload is credentials of the user
	  */
	public login(payload): Observable<any> {
		const url: string = ApiRoutes.login;
		return this.http.post(url, payload).pipe(map((data: any) => {
			if (data.token) {
				this.ls.setItem('token', data.token);
				this.ls.setItem('likedyou-currentUser-' + data.token, JSON.stringify(data.user));
				this.ls.setItem('likedyou-currentUser-businessName',data.user.businessName);
				this.token = data.token;
				const user = data.user;
				if(user) {
					this.role = user.role;
					this.businessLevel = user.Businesslevel;
					this.userId = user.id;
					this.actualAddedBy = user.addedBy;
					this.addedBy = (user.role == 'admin' || (user.role == 'businessOwner' && user.addedBy == 1)) ? user.id : user.addedBy;
				}
			}
			return data;
		}));
	}

	/** 
	  * Business Owner First Login
	  * @param payload is credentials of the user
	  */
	public businessOwnerLogin(payload): Observable<any> {
		const url: string = ApiRoutes.businessOwnerLogin;
		return this.http.post(url, payload).pipe(map((data: any) => {
			if (data.token) {
				this.ls.setItem('token', data.token);
				this.ls.setItem('likedyou-currentUser-' + data.token, JSON.stringify(data.user));
				this.token = data.token;
				const user = data.user;
				if(user) {
					this.role = user.role;
					this.businessLevel = user.Businesslevel;
					this.userId = user.id;
					this.actualAddedBy = user.addedBy;
					this.addedBy = (user.role == 'admin' || (user.role == 'businessOwner' && user.addedBy == 1)) ? user.id : user.addedBy;
				}
			}
			return data;
		}));
	}


	public tokenVerification(payload, type): Observable<any> {
		const url: string = (type == 'user') ? ApiRoutes.tokenVerification : ApiRoutes.spTokenVerification;
		return this.http.post(url, payload);
	}

	public checkExpireResetPass(payload){
		const url : string = ApiRoutes.checkExpireResetPass;
		return this.http.post(url , payload);
	}

	public businessOwnerSetPassword(payload): Observable<any> {
		const url: string = ApiRoutes.businessOwnerSetPassword; 
		return this.http.post(url, payload).pipe(map((data: any) => {
			if (data.token) {
				this.ls.setItem('token', data.token);
				this.ls.setItem('likedyou-currentUser-' + data.token, JSON.stringify(data.user));
				this.token = data.token;
				const user = data.user;
				if(user) {
					this.role = user.role;
					this.businessLevel = user.Businesslevel;
					this.userId = user.id;
					this.actualAddedBy = user.addedBy;
					this.addedBy = (user.role == 'admin' || (user.role == 'businessOwner' && user.addedBy == 1)) ? user.id : user.addedBy;
				}
			}
			return data;
		}));
	}

	//* get decoded token */
	public getToken(): string {
		const helper = new JwtHelperService();
		return helper.decodeToken(this.token);
	}

	/** 
	* Login action
	* @param token is JWT token
	*/
	public set token(token: string) {
		this.authToken = token;
		this.changeAuthState = !!token;
	}

	//* get auth token */
	public get token(): string {
		return this.authToken ? this.authToken : '';
	}

	/** 
	* Change auth state of a user
	* @param newState of the user
	*/
	public set changeAuthState(newState: boolean) {
		this.authState.next(newState);
	}

	//** Method to check if user logged in */
	public isAuthenticated(): boolean {
		return !!this.token;
	}

	//** Logiut action */
	public logout(): void {
		this.ls.clear();
		this.token = null;
		this.changeAuthState = !!this.token;
		this.router.navigate([APP_ROUTES.login]);
	}
}
